import { gql } from '@apollo/client';

// ==============> LOGIN <================
export const SAVE_TRADOS_ID = gql`
query SaveTradosId($fileUrl:String,$barzanoId:String, $workflowId:String, $projectId:String,$lang:String, $token:String){
  saveTradoProjectId(fileUrl:$fileUrl,barzanoId:$barzanoId, workflowId:$workflowId, projectId:$projectId,lang:$lang, token:$token)
}
`
export const CLIENT_EMAIL_VERIFICATION = gql`
query ClientEmail($email:String){
  verifyClientEmail(email:$email)
}
`
export const USER_DETAILS = gql`
query UserByEmail($email:String){
  userByEmail(email:$email){
    email
    avatarUrl
  }
}
`
export const GET_LINGUIST_TRADOS_EMAIL = gql`
query GetTradosEmail($email:String){
  getTradosEmail(email:$email)
}
`
export const LOGIN_USER = gql`
  query Query($email: String, $password: String,  $recaptcha:String) {
    users(email: $email, password: $password , recaptcha:$recaptcha) {
      id
      email 
      role
      name
      type
      phoneNumber
      avatarUrl
      department
      description
      jobTitle
      token
      language{
        code
        name
        nativename
      }
      personalEmail
      address1
      address2
      city
      postcode
      dob
      country{
        code
        label
        phone
      }
    }
  }
`;

export const FORGOT_PASSWORD_LINGUIST = gql`
query Query($email: String) {
  forgotPassword(email: $email)
}`

export const LOGIN_LINGUIST = gql`
 query LinguistUsers($email: String, $password: String, $recaptcha:String) {
  linguistUsers(email: $email, password: $password , recaptcha:$recaptcha) {
    id
    firstName
    lastName
    email
    profileStatus
    type
    token
    name
    avatarUrl
    image
    profileCompletion
  }
}
`;
export const SEND_EMAIL_VERIFICATION_CODE = gql`
query SendVerifyEmail($email: String) {
  sendUserVerificationCode(email: $email)
}`
export const VERIFY_USER = gql`
query VerifyUserEmail($email: String,  $authCode: String) {
  verifyUserEmail(email: $email, authCode: $authCode){
    id
    firstName
    lastName
    email
    profileStatus
    type
    token
    name
    avatarUrl
    image
  }
}`

// ==============> CALENDAR <================


export const ALL_CALENDAR = gql`
query Query {
  allCalendar {
    allDay
    end
    id
    projectId
    start
    textColor
    title
  }
}`
export const BARZANO_CALENDAR = gql`
query Query {
  barzanoCalendar {
    allDay
    end
    id
    projectId
    start
    textColor
    title
    totalPages
  }
}`
export const FORMATTER_PROJECTS_CALENDAR = gql`
query ProjectsCalender($formatterId: String) {
  projectsCalender(formatterID: $formatterId) {
    id
    title
    start
    textColor
  }
}`
// ==============> TASKS <================
export const ALL__PROJECTS_LIST = gql`
query GetAllGeneralOrders(
  $offset: Int
  $filterBrand: [String]
  $filterInvoice: [String]
  $filterDeliveryDate: [String]
  $filterStatus: [String]
  $filterCreationDate: [String]
  $filterOrderNo: String
  $filterInvoiceNo: String
  $limit: Int
  $orderBy: String
  $order: String
) {
  getAllGeneralOrders(
    offset: $offset
    filterBrand: $filterBrand
    filterInvoice: $filterInvoice
    filterDeliveryDate: $filterDeliveryDate
    filterStatus: $filterStatus
    filterCreationDate: $filterCreationDate
    filterOrderNo: $filterOrderNo
    filterInvoiceNo: $filterInvoiceNo
    limit: $limit
    orderBy: $orderBy
    order: $order
  )
}
`;
export const ALLTASKS_DETAILS = gql`
  query AllTasks(
    $type:String
    $department:String
    $filterStatus: [String], 
    $filterCreationDate: String, 
    $filterDueDate: String,
    $filterName: String
    $offset: Int,
     $limit: Int, 
     $order: String, 
     $orderBy: String,
    ) {
    allTasks(
      type:$type
      department:$department
      filterStatus: $filterStatus
      filterCreationDate: $filterCreationDate
      filterDueDate: $filterDueDate
      filterName: $filterName
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
    ) {
      tasks {
        id
        taskNumber
        name
        summary
        priority
        assignorName {
          name
          email
        }
        assigneeName {
          name
          email
        }
        createdAtDate
        dueDate
        status
      }
      tasksCount {
        filterCount
        totalCount
        doneCount
        pendingCount
        notStartedCount
      }
    }
  }
`;

export const TASK_DETAILS = gql`
  query Query($taskId: ID) {
    tasks(taskId: $taskId) {
      id
      name
      taskNumber
      summary
      priority
      assignorName {
        name
        email
      }
      assigneeName {
        name
        email
      }
      createdAtDate
      dueDate
      level
      status
    }
  }
`;

export const DELTASKS_DETAILS = gql`
  query Query($deleteTasksId: ID!) {
    deleteTasks(id: $deleteTasksId)
  }
`;

// ==============> COMPANY <================
export const COMPANY_CONTACTS_BOOKEDBY = gql`
query CompanyContacts($companyId: ID) {
  CompanyContactsBookedBy(companyId: $companyId) {
    firstName
    lastName
    email
  }
}
`
export const COMPANY_NAMES_AND_IDS = gql`
query CompanyNamesAndIds {
  companyNamesAndIds {
    id
    name
    paymentTerms
    address1
    address2
    city
    postcode
    VAT
  }
}
`;
export const COMPANY_TOTAL_SALES = gql`
query CompanyTotalSalesFilter( $id: String, $year: String) {
  companyTotalSalesFilter(id: $id, year: $year)
}
`
export const COMPANY_MONTHLY_SALES = gql`
query CompanySalesPerMonth($id: String, $year: String) {
  companySalesPerMonth(id: $id, year: $year) 
}
`
export const COMPANY_QUOTES_COUNT = gql`
query CompanyOrders($id:String){
  companyOrders(id:$id){
    sentToPM
    quoteOpen
    quoteClosed
  }
}

`;
export const COMPANY_DETAILS = gql`
  query Companies($companyId: ID!) {
    company(companyId: $companyId) {
      name
      id
      industry {
        code
        name
      }
      website
      VAT
      taxCode
      SDIcode
      generalEmail
      brand
      pecEmail
      turnover
      currentCreditScore
      previousCreditScore
      accountManager {
        name
        email
      }
      generalPhone
      stage
      address1
      address2
      city
      postcode
      country {
        code
        label
        phone
      }
      state {
        code
        province
        region
      }
      createdAtDate
      estimatedBudget
      serviceLevel
      paymentTerms
      salesPotential
      marketingChannel{
        source
        sourceDetails{
          utm
          ppcSource
          cpn
          gad_source
          adgroup
          kwid
          campaignGclid
          keywords
        }
      }
      companyNumber
      notes
      pmNotes
      linguistNotes
      financeNotes
      followUp
      testimonial
    }
  }
`;

export const ALL_COMPANIES = gql`
  query AllCompanies(
  $filterLeadSource:String
    $filterStage: [String], 
    $filterBrand: [String], 
    $filterName: String, 
    $filterCreationDate: [String], 
    $filterFollowUpDate: [String], 
    $filterLevel: String ,
    $salesPotential: String ,
    $filterCountry: String,
    $filterAccountManager :String
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    ) {
    allCompanies(
      filterLeadSource:$filterLeadSource
      filterStage: $filterStage
      filterBrand: $filterBrand
      filterName: $filterName
      filterCreationDate: $filterCreationDate
      filterFollowUpDate: $filterFollowUpDate
      filterLevel: $filterLevel
      salesPotential: $salesPotential
      filterCountry: $filterCountry
      filterAccountManager:$filterAccountManager
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
    ) {
      companies {
        id
        companyNumber
        totalSales
        name
        followUp
        brand
        turnover
        salesPotential
        accountManager {
          name
          email
        }
        stage
        createdAtDate
        industry {
          code
          name
        }
        serviceLevel
      }
      companiesCount {
        filterCount
        totalCount
        leadCount
        prospectCount
        customerCount
      }
    }
  }
`;

export const DEL_COMPANIES = gql`
  query Query($deleteCompaniesId: ID!) {
    deleteCompanies(id: $deleteCompaniesId)
  }
`;

// ==============> COMPANY CONTACTS <================
export const VERIFY_COMPANY_CONTACT = gql`
query VerifyCompanyContact($email:String){
  verifyCompanyContact(email:$email)
}
`

export const ALL_COMPANY_CONTACTS = gql`
  query AllCompanyContacts {
    allCompanyContacts {
      address1
      address2
      city
      companyId
      country {
        code
        label
        phone
      }
      createdAtDate
      email
      firstName
      gender
      id
      lastName
      phone
      postcode
      role
      status
    }
  }
`;
export const DELETE_COMPANY_CONTACT = gql`
query deleteContact($deleteCompanyContactId: String) {
  deleteCompanyContacts(id: $deleteCompanyContactId)
}
`
export const COMPANY_CONTACTS = gql`
  query CompanyContacts($companyId: ID, $contact:String, $offset: Int, $limit: Int,) {
    CompanyContacts(companyId: $companyId, contact:$contact, limit:$limit, offset:$offset) {
      allContactsCount
     companyContacts{ 
      address1
      address2
      city
      companyId
      state {
        code
        province
        region
      }
      country {
        code
        label
        phone
      }
      createdAtDate
      email
      firstName
      gender
      id
      lastName
      phone
      postCode
      role
      status
      jobTitle
      taxCode
      isAccountRegistered
     }
    }
  }
`;

// ==============> EXPENSE ALLOCATION <================

export const ALL_EXPENSE_ALLOCATIONS = gql`
query Query($projectId: ID) {
  ExpenseAllocations(projectId: $projectId) {
    id
    price
    projectId
    quantity
    service
    total
    title
    type
    endDateTime
    file
    technician {
      id
      firstName
      lastName
      email
    }
    formatter {
      id
      firstName
      lastName
      email
    }
    technicianNotes
    status
    locationContact {
      fullName
      email
      phone
    }
    serviceProvided
    startDateTime
    endDateTime
  }
}`;

export const LIST_EXPENSE_ALLOCATIONS = gql`
query AllExpenseAllocations($projectId: [String]){
  allExpenseAllocations (projectId: $projectId){
    id
    price
    projectId
    quantity
    service
    total
    title
    type
  }
}`;





// ==============> LINGUIST ALLOCATION <================

export const ALL_LINGUIST_ALLOCATIONS = gql`
  query LinguistAllocations($projectId: ID!) {
    LinguistAllocations(projectId: $projectId) {
      id
      total
      creationDate
      deliveryDateTime
      email
      tradosEmail
      status
      item_id
      firstName
      lastName
      gender
      phone
      ratePerWord
      notes
      ratePerHour
      ratePerAdditionalHour
      startDateTime
      endDateTime
      sourceLanguage
      targetLanguage
      contactMethod
      dob
      mainLanguage
      otherLanguage
      newWords
      country
      city
      linguistId
      iban
      resume
      projectId
      negotiatedDeliveryDateTime
      negotiatedRatePerWord
      negotiatedTotal
      negotiatedRatePerAdditionalHour
      negotiatedRatePerHour
      linguistDeliveryDate
      pmFeedback{
        deliveryCompliance,
        communicationAndAvailability,
        layoutAndFormating
      }
      prFeedback{
        accuracyTranslation
        grammerSpelling
        technicalTerminology
      }
    }
  }
`;
export const LIST_LINGUIST_ALLOCATIONS = gql`
  query AllLinguistAllocations($projectId: [String]) {
    allLinguistAllocations(projectId: $projectId) {
      id
      total     
      linguistId    
      projectId
    }
  }
`;


export const DELETE_ALLOCATION = gql`
query Query($deleteAllocationId: ID!, $allocationEmail: allocationEmail) {
  deleteAllocation(id: $deleteAllocationId, allocationEmail: $allocationEmail)
}`
export const DELETE_EXPENSE = gql`
query DeleteExpenseAllocations($deleteExpenseAllocationsId: ID!) {
  deleteExpenseAllocations(id: $deleteExpenseAllocationsId)
}

`

export const LINGUIST_RESPONSE = gql`
  query singleAllocation($singleAllocationId: ID!) {
    singleAllocation(id: $singleAllocationId) {
      firstName
      lastName
      ratePerWord
      linguistId
      ratePerHour
      status
      deliveryDateTime
      sourceLanguage
      targetLanguage
      startDateTime
      endDateTime
      ratePerAdditionalHour
      orderNumber
      projectManager
      email
      notes
      projectId
      id
      total
      projectType
      newWords
      prFeedback {
        accuracyTranslation
        grammerSpelling
        technicalTerminology
      }
      pmFeedback {
        communicationAndAvailability
        deliveryCompliance
        layoutAndFormating
      }
    }
  }
`;
export const LINGUIST_ALLOCATION_BY_ID = gql`
query linguistAllocationById($singleAllocationId: ID!, $email: String!) {
  singleAllocation(id: $singleAllocationId) {
    firstName
    lastName
    ratePerWord
    linguistId
    ratePerHour
    status
    deliveryDateTime
    sourceLanguage
    targetLanguage
    startDateTime
    endDateTime
    ratePerAdditionalHour
    orderNumber
    projectManager
    email
    notes
    projectId
    id
    total
    projectType
    newWords
    phone
    otherLanguage
    negotiatedTotal
    negotiatedRatePerWord
    negotiatedRatePerHour
    negotiatedRatePerAdditionalHour
    negotiatedDeliveryDateTime
    mainLanguage
    linguistDeliveryDate
    gender
    dob
    creationDate
    completionDate
    country
    city
    prFeedback {
      accuracyTranslation
      grammerSpelling
      technicalTerminology
    }
    pmFeedback {
      communicationAndAvailability
      deliveryCompliance
      layoutAndFormating
    }
    feedbackBZ{
      deliveryCompliance
      communicationAndAvailability
      layoutAndFormating
      translationAccuracy
      tmManagement
    }
    feedbackCI {
      quality
      ethicalStandards
      costEffective
      communication
      timeliness
  }
  }
  getUserData(email: $email) {
    firstName
    lastName
    image
    email
    resume
    dob
    phone
    mainLanguage
    otherLanguage
    country
  }
}
`;
export const PR_RESPONSE = gql`
  query singlePRAllocation($singleAllocationId: ID!) {
    singlePRAllocation(id: $singleAllocationId) {
      firstName
      lastName
      ratePerWord
      linguistId
      ratePerHour
      status
      deliveryDateTime
      sourceLanguage
      targetLanguage
      startDateTime
      endDateTime
      ratePerAdditionalHour
      orderNumber
      projectManager
      email
      notes
      projectId
      id
      total
      projectType
      newWords
    }
  }
`;
// ==============> USERS <================
export const MANAGERS_LIST = gql`
query ManagersList($department: String) {
  managersList(department: $department) {
    email
      name
      jobTitle
      phoneNumber
      avatarUrl
      language {
      code
      name
      nativename
    }
  }
}
`
export const ALL_TECHNICIANS = gql`
query allTechnicians{
  allTechnicians{
    firstName
    lastName
  }
}
`
export const ALL_USERS_DETAILS = gql`
  query allUsers {
    allUsers {
      email
      name
      jobTitle
      phoneNumber
      avatarUrl
      language {
      code
      name
      nativename
    }
    }
  }
`;

export const ALL_CARDS_DETAILS = gql`
  query allUsers {
    allUsers {
      id
      email
      role
      name
      phoneNumber
      avatarUrl
      department
      description
      jobTitle
    }
  }
`;

// ==============> INTERPRETATION PROJECT <================
export const INTERPRETATION_SERVICE = gql`
query ServiceInterpretation {
  serviceInterpretation {
    serviceItems {
      Code
      InvCode
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
    }
    addService {
      Code
      InvCode
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
    }
  }
}
`
export const INTERPRETATION_PROJECT_DETAILS = gql`
  query InterpretationOrder($interpretationOrderId: ID!) {
    interpretationOrder(interpretationOrderId: $interpretationOrderId) {
      id
      orderNumber
     taxes
     totalDiscount
      grandTotal
      netTotal
    quoteNumber
    isBillingCompleted
    quoteId
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
        id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
      }
      projectManager {
        name
        email
      }
      accountManager {
        name
        email
        avatarUrl
      }
      address1
      interpretationItem {
        discount
        item_id
        isPreBookingSent
        isBookingSent
        sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
        service{
          Code
          InvCode
          Description{
            italian
  english
          }
          Service{
            italian
  english
          }
        }
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      subtotal
      subject
      address2
        postCode
        city
        country {
          code
          label
          phone
        }
      bookedBy{
        firstName
        lastName
        email
      }
      status
      supportMaterialLink
      createdAtDate
      startDate
      endDate
      notes
      serviceSubtotal
      additionalService {
        title
        startDateTime
        endDateTime
        price
        discount

        quantity
        service{
          Code
          InvCode
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
        }
        serviceTotal
      }
  
    }
  }
`;


export const TECHNICIAN_SHEET = gql`
query TechnicianExpenseById($expenseId: ID) {
  technicianExpenseById(expenseId: $expenseId) {
    expenseDetail {
      id
      title
      status
      service
      total
      technicianNotes
      startDateTime
      endDateTime
      locationContact {
        fullName
        phone
        email
      }
      serviceProvided
    }
    project {
      id
      projectManager {
        name
      }
      orderNumber
      bookedBy {
        firstName
        lastName 
       } 
        companyNameAndId {
        name
        
      }
      address1
      address2
      city
      country {
        label
      }
      
      interpretationItem {
        startDateTime
        endDateTime
        title
      }
    }
    linguists {
      firstName
      lastName
      mainLanguage
      otherLanguage
      phone
      email
      sourceLanguage
      targetLanguage
    }
  }
}    
`;

//==================> BROCHURE =============================>
export const BROCHURE_DETAILS = gql`
  query brochure($brochureId: ID!) {
    brochure(brochureId: $brochureId) {
      id
      brochureNumber
      companyLogo
      clients{
          name
          logo
        }
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
      }
      accountManager {
        name
        email
        phoneNumber
        jobTitle
        avatarUrl
        language {
      code
      name
      nativename
    }
      }
      address1
  
      bookedBy{
        firstName
        lastName
        email
      }
      quote
      createdAtDate
      
    }
  }
`;
export const ALL_BROCHURE_DETAILS = gql`
  query AllBrochures(
    $filterStatus: [String]
    $filterNumber: String
    $filterCreationDate: [String]
    $filterAccountManager:String
    $limit: Int
    $offset: Int
    $order: String
    $orderBy: String
    
  ) {
    allBrochures(
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterAccountManager: $filterAccountManager
      limit: $limit
      offset: $offset
      order: $order
      orderBy: $orderBy
    ) {
      brochures {
        id
        brochureNumber
        companyNameAndId {
          id
        name
        }
        accountManager {
          name
          email
        }
        bookedBy{
          firstName
          lastName
        }
        quote
        createdAtDate
        address1
        
      }
      brochuresCount {
        filterCount,        
      }
    }
  }
`;
export const GET_INTERPRETATION_BY_ID = gql`
  query InterpretationOrder($interpretationOrderId: ID!) {
    interpretationOrder(interpretationOrderId: $interpretationOrderId) {
      id
      orderNumber
      address1
      subject
      address2
      postCode
      city
      country {
        code
        label
        phone
      }
      notes
    }
  }
`;

export const ALL_INTERPRETATION_PROJECT_DETAILS = gql`
  query AllInterpretationOrders(
    $filterStatus: [String]
    $filterNumber: String
    $filterCreationDate: [String]
    $filterStartDate: [String]
    $filterProjectManager:String
    $limit: Int
    $offset: Int
    $order: String
    $orderBy: String
  ) {
    allInterpretationOrders(
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterStartDate: $filterStartDate
      filterProjectManager: $filterProjectManager
      limit: $limit
      offset: $offset
      order: $order
    orderBy: $orderBy
    ) {
      interpretationOrders {
        id
        netTotal
        orderNumber
        margin
        companyNameAndId {
          id
          name
        }
        projectManager {
          name
          email
        }
        bookedBy{
          firstName
          lastName
        }
        interpretationItem{
          sourceLanguage{
        code
        name
        nativename
      }
          quantity
          targetLanguage{
        code
        name
        nativename
      }
        }
        status
        createdAtDate
        startDate
        address1
        address2
        subtotal
        postCode
        country {
        code
        label
        phone
      }
      additionalService {
        price
        title
        quantity
        discount
       }
        stage
      }
      interpretationOrderCount {
        totalCount
        filterCount
        deliveredCount,
        allocatedCount,
        claimedCount,
      }
    }
  }
`;

export const DEL_INTERPRETATION_PROJECT_DETAILS = gql`
  query Query($deleteInterpretationOrdersId: ID!) {
    deleteIntepretationOrders(id: $deleteInterpretationOrdersId)
  }
`;
export const INTERPRETATION_QUOTE_DETAILS = gql`
  query InterpretationOrder($interpretationOrderId: ID!) {
    interpretationOrder(interpretationOrderId: $interpretationOrderId) {
      id
      orderNumber
      language
      marketingChannel{
        type
        details{
          source
        sourceDetails{
          utm
          campaignGclid
          ppcSource
          cpn
          gad_source
          adgroup
          kwid
          keywords
        }
        }
        
      }
      companyLogo
      totalDiscount
      quoteSignature
      reasonForClosure
      grandTotal
      netTotal
      optionSelected
      taxesOptions
    totalDiscountOptions
    netTotalOptions
    grandTotalOptions
    subtotalOptions
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
      }
      accountManager {
        name
        email
        phoneNumber
        jobTitle
        avatarUrl
        language {
      code
      name
      nativename
    }
      }
      address1
      taxes
      interpretationItem {
        sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
        service{
          Code
          InvCode
          Description{
            italian
  english
          }
          Service{
            italian
  english
          }
        }
        discount
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      interpretationItem2 {
        sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
        service{
          Code
          InvCode
          Description{
            italian
  english
          }
          Service{
            italian
  english
          }
        }
        discount
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      subtotal
      subject
      address2
        postCode
        city
        country {
          code
          label
          phone
        }
      bookedBy{
        firstName
        lastName
        email
      }
      status
      supportMaterialLink
      createdAtDate
      startDate
      notes
      notesOptions
      pmNotes
      quoteDeadline
      followUpDate
      serviceSubtotal
      additionalService {
        title
        price
        quantity
        startDateTime
        endDateTime
        discount
        service{
          Code
          InvCode
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
        }
        serviceTotal
      }
      additionalService2 {
        title
        price
        quantity
        startDateTime
        endDateTime
        discount
        service{
          Code
          InvCode
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
        }
        serviceTotal
      }
      addOns {
      cover
      quoteInfo
      packages
      companyInfo
      noOfEvents
      handleEvent
      hostessService
      location
      friendly
      sector
      remoteEventAddress
    }
    brand{
      rafiky
      interpretation
    }
    defaultPkg
    clients{
          name
          logo
        }
    }
  }
`;
export const ALL_QUOTES_MARKETING_REPORTS = gql`
query QuotesMarketingReports($creationDate: [String], $filterBrand:String, $year:String){
  quotesMarketingReports(filterBrand:$filterBrand, year:$year, creationDate: $creationDate){
    status
    marketingChannel
  }
}
`
export const ALL_QUOTES_DETAILS = gql`
query AllQuoteOrders(
  $filterStatus: [String]
    $filterCreationDate: [String]
    $filterQuoteSource:[String]
    $filterBrand:[String]
    $filterAccountManager:String
    $limit: Int
    $offset: Int
    $order:String, $orderBy:String
){
  allQuoteOrders(
    filterStatus: $filterStatus
      filterCreationDate: $filterCreationDate
      filterQuoteSource: $filterQuoteSource
      filterBrand:$filterBrand
      filterAccountManager:$filterAccountManager
      limit: $limit
      offset: $offset
      order:$order
      orderBy:$orderBy
  ){
   orders{
        id
        orderNumber
        marketingChannel
        companyName
        bookedBy
        createdAtDate
        createdAtDateTime
        orderType
        netTotal
        status
        accountManagerName
   }
   ordersCount
   filteredTotals{
    totalQuotes
    convertedQuotes 
    filteredNetTotal
     filteredConvertedTotal
     }
   }
}
`
export const ALL_INTERPRETATION_QUOTE_DETAILS = gql`
  query AllInterpretationQuoteOrders(
    $filterStatus: [String]
    $filterNumber: String
    $filterCreationDate: [String]
    $filterStartDate: [String]
    $filterAccountManager:String
    $limit: Int
    $offset: Int
    $order: String
    $orderBy: String
    
  ) {
    allInterpretationQuoteOrders(
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterStartDate: $filterStartDate
      filterAccountManager: $filterAccountManager
      limit: $limit
      offset: $offset
      order: $order
      orderBy: $orderBy
    ) {
      interpretationOrders {
        id
        orderNumber
        netTotal
        followUpDate
        utm
        campaignGclid
        reasonForClosure
        source
        companyNameAndId {
          id
        name
        }
        accountManager {
          name
          email
        }
        bookedBy{
          firstName
          lastName
        }
        interpretationItem{
          sourceLanguage{
        code
        name
        nativename
      }
          quantity
          targetLanguage{
        code
        name
        nativename
      }
        }
        status
        createdAtDate
        startDate
        address1
        address2
        subtotal
        pmNotes
        postCode
        country {
        code
        label
        phone
      }
        stage
      }
      interpretationOrderCount {
        filterCount,
        TBSCount,
              closedCount,
              openCount,
              totalCount,
              awardedCount,
      }
    }
  }
`;
export const INTERPRETATION_QUOTE_EMAIL = gql`
  query quoteInterpretationEmail($language:String, $subject:String, $email: String, $body: String, $quoteEmailId: ID, $managerName: String, $jobTitle: String, $phoneNumber:String) {
  quoteInterpretationEmail(language:$language, subject:$subject, email: $email, body: $body, id: $quoteEmailId, managerName:$managerName, jobTitle:$jobTitle, phoneNumber:$phoneNumber){
    id
      orderNumber
     quoteDeadline
     totalDiscount
     netTotal
      grandTotal
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
      }
      accountManager {
        name
        email
        avatarUrl
        phoneNumber
        jobTitle
      }
      address1
      taxes
      interpretationItem {
        sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
        service{
          Code
          Description{
            italian
  english
          }
          Service{
            italian
  english
          }
        }
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      subtotal
      subject
      address2
        postCode
        city
        country {
          code
          label
          phone
        }
      bookedBy{
        firstName
        lastName
        email
      }
      status
      supportMaterialLink
      createdAtDate
      startDate
      notes
      serviceSubtotal
      additionalService {
        title
        price
        discount
        quantity
        service{
          Code
      Description{
        italian
  english
      }
      Service{
        italian
  english
      }
        }
        serviceTotal
      }
      addOns {
      cover
      handleEvent
      hostessService
      location
      packages
      quoteInfo
      companyInfo
      noOfEvents
    }
    }
  }
`;
// ==============> VIDEO CONFERENCE PROJECT <================

export const VIDEO_CONFERENCE_PROJECT_DETAILS = gql`
  query VideoConferenceOrder($videoConferenceOrderId: ID!) {
    videoConferenceOrder(videoConferenceOrderId: $videoConferenceOrderId) {
      id
      orderNumber
      sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
      }
      projectManager {
        name
        email
      }
      accountManager {
        name
        email
      }
      videoConferenceItem {
        subject
        type
        startDateTime
        endDateTime
        interpreterNumber
        timezone
      }
      subtotal
      bookedBy
      status
      supportMaterialLink
      createdAtDate
      startDate
      notes
      additionalService {
        description
        price
        quantity
        service
        serviceTotal
      }
      serviceSubtotal
    }
  }
`;

export const ALL_VIDEO_CONFERENCE_PROJECT_DETAILS = gql`
  query AllVideoConferenceOrders(
    $filterStatus: String
    $filterNumber: Int
    $filterCreationDate: String
    $filterStartDate: String
  ) {
    allVideoConferenceOrders(
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterStartDate: $filterStartDate
    ) {
      videoConferenceOrders {
        id
        orderNumber
        companyNameAndId {
          id
          name
        }
        projectManager {
          name
          email
        }
        bookedBy
        status
        createdAtDate
        startDate
      }
      videoConferenceOrderCount {
        totalCount
        deliveredCount
        allocatedCount
        claimedCount
      }
    }
  }
`;

export const DEL_VIDEO_CONFERENCE_PROJECT_DETAILS = gql`
  query Query($deleteVideoConferenceOrdersId: ID!) {
    deleteVideoConferenceOrders(id: $deleteVideoConferenceOrdersId)
  }
`;

// ==============> TRANSLATION PROJECT <================
export const TRANSLATION_SERVICE = gql`
query ServiceTranslation {
  serviceTranslation {
    serviceItems {
      Item{
        italian
        english
      }
      Code
      InvCode
      Description{
        italian
        english
      }
    }
    addService {
      Code
      InvCode
      Item{
        italian
        english
      }
      Description{
        italian
        english
      }
    }
  }
}
`
export const ALL_INTERPRETATION_PROJECTS_SALES = gql`
query GetInterpretationProjectsTotal($year:String){
  getInterpretationProjectsTotal(year:$year){
    subtotal
    netTotal
    orderNumber
    interpretationItem{
      total
      quantity
        price
        discount
      service {
        InvCode
      }
    }
    additionalService {
      serviceTotal
      quantity
        price
        discount
      service{
        InvCode
      }
    }
  }
}
`
export const ALL_BARZANO_PROJECTS_SALES = gql`
query GetBarzanoProjectsTotal($year:String){
  getBarzanoProjectsTotal(year:$year){
    subtotal
    orderNumber
    netTotal
    barzanoItem{
      total
      totalWords
      pricePerWord
      discount
      service {
        InvCode
      }
    }
    additionalService {
      serviceTotal
      quantity
          discount  
          price
      service{
        InvCode
      }
    }
  }
}
`
export const ALL_TRANSLATION_PROJECTS_SALES = gql`
query GetTranslationProjectsTotal($year:String){
  getTranslationProjectsTotal(year:$year){
    subtotal
    orderNumber
    netTotal
    translationItem{
      total
      totalWords
      pricePerWord
      discount
      service {
        InvCode
      }
    }
    additionalService {
      serviceTotal
      quantity
          discount  
          price
      service{
        InvCode
      }
    }
  }
}
`
export const ALL_TRANSLATION_PROJECT_DETAILS = gql`
  query Query($filterProjectManager:String, $filterStatus: [String], 
  $filterNumber: String, $filterCreationDate: [String], $filterDeliveryDate: [String] ,  
    $limit: Int,
      $offset: Int
      $order: String
    $orderBy: String
    ) {
    allTranslationOrders(
      filterProjectManager:$filterProjectManager
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterDeliveryDateTime: $filterDeliveryDate
      limit: $limit
      offset: $offset
      order: $order
    orderBy: $orderBy
    ) {
      translationOrders {
        id
        orderNumber
        linguistSubtotal
        subtotal
        margin
        sumTotalWords
        netTotal
        additionalService {
          price
          title
          quantity
          discount        
          }
        companyNameAndId {
          id
          name
        }
        projectManager {
          name
          email
        }
        accountManager{
          name
        }
        bookedBy{
          firstName
          lastName
        }
        translationItem{
          totalWords
          discount
          sourceLanguage {
          code
          name
           nativename
           }
           targetLanguage {
           code
            name
           nativename
           }
          pricePerWord
        }
        status
        createdAtDate
        deliveryDateTime
      }
      translationOrderCount {
        filterCount
        totalCount
        deliveredCount
        allocatedCount
        claimedCount
      }
    }
  }
`;
export const ALL_TRANSLATION_QUOTE_DETAILS = gql`
  query Query(
    $filterAccountManager:String, 
    $serviceType: String,
    $filterStatus: [String],
    $filterNumber: String,
    $filterCreationDate: [String], 
    $filterDeliveryDate: [String]
    $limit: Int
    $offset: Int
    $order: String
    $orderBy: String
    ) {
    allTranslationQuoteOrders(
      filterAccountManager:$filterAccountManager
      serviceType: $serviceType
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCreationDate: $filterCreationDate
      filterDeliveryDateTime: $filterDeliveryDate
      limit: $limit
      offset: $offset
      order: $order
      orderBy: $orderBy
    ) {
      translationOrders {
        id
        orderNumber
        linguistSubtotal
        subtotal
        utm
        reasonForClosure
        campaignGclid
        source
        netTotal
        followUpDate
        companyNameAndId {
          id
          name
        }
        accountManager{
          name
        }
        bookedBy{
          firstName
          lastName
        }
        translationItem{
          totalWords
          sourceLanguage {
          code
          name
           nativename
           }
           targetLanguage {
           code
            name
           nativename
           }
          pricePerWord
        }
        status
        createdAtDate
        deliveryDateTime
        pmNotes
      
      }
      translationOrderCount {
        filterCount
        totalCount
        closedCount
        openCount
        TBSCount
        awardedCount
      }
    }
  }
`;
export const TRANSLATION_PROJECT_STATUS = gql`
query GetTranslationOrderClientData($translationOrderId: String!) {
  getTranslationOrderClientData(id: $translationOrderId)
}
`
export const TRANSLATION_PROJECT_DETAILS = gql`
  query TranslationOrder($translationOrderId: ID!) {
    translationOrder(translationOrderId: $translationOrderId) {
    id
    orderNumber
    taxes
    netTotal
    quoteNumber
    quoteId
    totalDiscount
    grandTotal
    isBillingCompleted
    addOns {
      urgency
    }
    industry {
      code
      name
    }
    companyNameAndId {
      id
      name
      id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
    }
    projectManager {
      name
      email
    }
    accountManager {
      name
      email
    }
    translationItem {
      fileName
      title
      isPreBookingSent
      isBookingSent
      item_id
      sourceLanguage {
      code
      name
      nativename
    }
    targetLanguage {
      code
      name
      nativename
    }
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      discount
    }
    deliveryDateTime
    subtotal
    linguistSubtotal
    bookedBy {
      firstName
      lastName
      email
    }
    status
    originalLink
    createdAtDate
    notes
    additionalService {
      price
      title
      quantity
      discount
      swornDeliveryDateTime
      service{
        Item{
          italian
        english
        }
      Code
      InvCode
      Description{
        italian
        english
      }
      }
      serviceTotal
    }
 
  }
  }
`;
export const TRANSLATION_QUOTE_DETAILS = gql`
  query TranslationOrder($translationOrderId: ID!) {
    translationOrder(translationOrderId: $translationOrderId) {
    id
    orderNumber
    notesOptions
    optionSelected
    utm
        campaignGclid
        source
        marketingChannel{
         type
         details{
          source
          sourceDetails{
            utm
            campaignGclid
            ppcSource
          cpn
          gad_source
          kwid
          adgroup
            keywords
          }
         }
        }
    language
    companyLogo
    taxes
    paymentLink{
        receipt_url
        url
        isPaid
        amount
      }
    quoteSignature
    totalDiscount
    grandTotal
    netTotal
    taxesOptions
    totalDiscountOptions
    netTotalOptions
    grandTotalOptions
    subtotalOptions
    workingDaysToDeadline
    reasonForClosure
    industry {
      code
      name
    }
    companyNameAndId {
      id
      name
      address1
    address2
    city
    postcode
    paymentTerms
    VAT
    }
    accountManager {
      name
      email
      avatarUrl
      jobTitle
      phoneNumber
      language {
      code
      name
      nativename
    }
    }
    translationItem {
      fileName
      title
      sourceLanguage {
      code
      name
      nativename
    }
    targetLanguage {
      code
      name
      nativename
    }
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      discount
    }
    translationItem2 {
      fileName
      title
      sourceLanguage {
      code
      name
      nativename
    }
    targetLanguage {
      code
      name
      nativename
    }
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      discount
    }
    deliveryDateTime
    quoteDeadline
    followUpDate
    subtotal
    linguistSubtotal
    bookedBy {
      firstName
      lastName
      email
    }
    status
    originalLink
    createdAtDate
    notes
    pmNotes
    additionalService {
      price
      title
      discount
      swornDeliveryDateTime
      quantity
      service{
        Item{
          italian
        english
        }
      Code
      InvCode
      Description{
        italian
        english
      }
      }
      serviceTotal
    }
    additionalService2 {
      price
      title
      discount
      swornDeliveryDateTime
      quantity
      service{
        Item{
          italian
        english
        }
      Code
      InvCode
      Description{
        italian
        english
      }
      }
      serviceTotal
    }
    addOns {
      seo
      cover
      website
      quoteInfo
      companyInfo
      packages
      urgency
      friendly
      sector
    }
    brand{
      lingoyou
      academy
    }
    clients{
          name
          logo
        }
  }
  }
`;
export const DEL_TRANSLATION_PROJECT_DETAILS = gql`
  query Query($deleteTranslationOrdersId: ID!) {
    deleteTranslationOrders(id: $deleteTranslationOrdersId)
  }
`;
export const DEL_TRANSLATION_QUOTE_DETAILS = gql`
  query Query($deleteTranslationOrdersId: ID!) {
    deleteTranslationOrders(id: $deleteTranslationOrdersId)
  }
`;
export const TRANSLATION_QUOTE_EMAIL = gql`
query QuoteTranslationEmail($language:String, $subject:String, $email: String, $body: String, $quoteTranslationEmailId: ID, $managerName: String, $jobTitle: String, $phoneNumber:String) {
  quoteTranslationEmail(language:$language, subject:$subject, email: $email, body: $body, id: $quoteTranslationEmailId, managerName:$managerName, jobTitle:$jobTitle, phoneNumber:$phoneNumber) {
    id
    orderNumber
    taxes
    netTotal
    industry {
      code
      name
    }
    companyNameAndId {
      id
      name
      paymentTerms
    }

    accountManager {
      name
      email
      avatarUrl
      phoneNumber
      jobTitle
    }
    stage
    deliveryDateTime
    quoteDeadline
    followUpDate
    translationItem {
      fileName
      title
      discount
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      sourceLanguage {
        code
        name
        nativename
      }
      targetLanguage {
        code
        name
        nativename
      }
    }
    subtotal
    linguistSubtotal
    bookedBy {
      firstName
      lastName
      email
    }
    status
    originalLink
    createdAtDate
    notes
    additionalService {
      title
      quantity
      price
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      serviceTotal
    }
    serviceSubtotal
  }
}
`
// ==============> BARZANO PROJECT <================

export const ALL_BARZANO_DELIVERY_PROJECTS = gql`
query AllBarzanoDeliveryOrders($filterStatus: [String] $filterDeliveryDateTime: String){
  allBarzanoDeliveryOrders(filterStatus: $filterStatus filterDeliveryDateTime: $filterDeliveryDateTime){
    id
        orderNumber
        caseNumber
        formattingDifficulty
        patentNumber
        bookedBy
        warningRequirements
        margin
        netTotal
        sumTotalWords
        sourceLanguage {
      code
      name
      nativename
    }
        targetLanguage {
      code
      name
      nativename
    }
        subtotal
        linguistSubtotal
        serviceSubtotal
        barzanoItem {
          totalWords
          pricePerWord
          total
          sourceLanguage {
            code
            name
            nativename
          }
          targetLanguage {
            code
            name
            nativename
          }
        }
        companyNameAndId {
          id
          name
        }
        projectManager {
          name
          email
        }
        bookedBy
        status
        createdAtDate
        deliveryDateTime
}
}
`
export const ALL_BARZANO_PROJECT_DETAILS = gql`
  query AllBarzanoOrders(
    $filterStatus: [String]
    $filterNumber: Int
    $filterCreationDate: [String]
    $filterProjectManager:String
    $filterDeliveryDateTime: [String]
    $filterCaseType:String
    $limit: Int
    $offset: Int
    $order: String
    $orderBy: String
  ) {
    allBarzanoOrders(
      filterStatus: $filterStatus
      filterNumber: $filterNumber
      filterCaseType:$filterCaseType
      filterCreationDate: $filterCreationDate
      filterDeliveryDateTime: $filterDeliveryDateTime
      filterProjectManager:$filterProjectManager
      limit: $limit
      offset: $offset
      order: $order
      orderBy: $orderBy
    ) {
      barzanoOrders {
        id
        orderNumber
        caseNumber
        formattingDifficulty
        patentNumber
        bookedBy
        warningRequirements
        margin
        netTotal
        sumTotalWords
    
        subtotal
        linguistSubtotal
        serviceSubtotal
        applicationType
        barzanoItem {
          totalWords
          pricePerWord
          total
          repetitionWords
          sourceLanguage {
            code
            name
            nativename
          }
              targetLanguage {
            code
            name
            nativename
          }
        }
        companyNameAndId {
          id
          name
        }
        projectManager {
          name
          email
        }
        bookedBy
        status
        createdAtDate
        deliveryDateTime
        additionalService {
          description
          price
          quantity
          serviceTotal
        }
      }
      barzanoOrderCount {
        filterCount
        totalCount
        deliveredCount
        allocatedCount
        claimedCount
      }
    }
  }
`;

export const DEL_BARZANO_PROJECT_DETAILS = gql`
  query Query($deleteBarzanoOrdersId: ID!) {
    deleteBarzanoOrders(id: $deleteBarzanoOrdersId)
  }
`;

export const GET_BARZANO_PROJECT_DETAILS = gql`
query BarzanoOrder($barzanoOrderId: ID!) {
    barzanoOrder(barzanoOrderId: $barzanoOrderId) {
      id
      orderNumber
      formattingDifficulty
      notes
    }
}
`
export const BARZANO_PROJECT_DETAILS = gql`
  query BarzanoOrder($barzanoOrderId: ID!) {
    barzanoOrder(barzanoOrderId: $barzanoOrderId) {
      id
      orderNumber
      caseNumber
      caseType
      taxes
      warningRequirements
      totalDiscount
      grandTotal
      netTotal
      formattingDifficulty
      patentNumber
      applicationType
      isBillingCompleted
 
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
      }
      projectManager {
        name
        email
      }
      accountManager {
        name
        email
      }
      tradosIds{
        projectId
        workflowId
      }
      barzanoItem {
        total
        item_id
        isPreBookingSent
        isBookingSent
        pricePerWord
        totalWords
        repetitionWords
        newWords
        patentTitle
        repetitionsPricePerWord
        fileName
        discount
        sourceLanguage {
          code
          name
          nativename
        }
        targetLanguage {
          code
          name
          nativename
        }
        service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      }
      subtotal
      bookedBy
      bookedByAddress
      status
      originalLink
      createdAtDate
      deliveryDateTime
      notes
      additionalService {
        description
        price
        quantity
        service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
        discount
        serviceTotal
      }
      serviceSubtotal
    }
  }
`;
// ==============> LINGUIST <================
export const ALL_LINGUIST_PROJECTS = gql`
query AllLinguistProjects($filterNumber: String, 
  $linguistId: String,
  $filterStatus: String, 
  $filterCreationDate: String,
  $filterDeliveryDateTime: String, 
  $filterProjectManager: String,
  $projectType: String!,
  $offset: Int, $limit: Int, $orderBy: String, $order: String
  ) {
  allLinguistProjects(
    filterNumber: $filterNumber, 
    linguistId: $linguistId, 
    filterStatus: $filterStatus, 
    filterCreationDate: $filterCreationDate, 
    filterDeliveryDateTime: $filterDeliveryDateTime, 
    filterProjectManager: $filterProjectManager, 
    projectType: $projectType,
    offset: $offset, limit: $limit, orderBy: $orderBy, order: $order) {
    linguistProjects {
      id
      linguistId
      projectType
      projectManager
      orderNumber
      status
      deliveryDateTime
      targetLanguage
      sourceLanguage
      total
      creationDate
      projectId
      startDateTime
      endDateTime
      type
    }
    linguistProjectCount {
      allocationCount
      completedCount
      acceptedCount
      totalCount
      pendingCount
      filterCount
    }
  }
}
`
export const LINGUIST_PROJECTS = gql`
query AllLinguistProjects($filterNumber: String, 
  $linguistId: String,
  $filterStatus: String, 
  $filterCreationDate: String,
  $filterDeliveryDateTime: String, 
  $filterProjectManager: String,
  $projectType: String!,
  $offset: Int, $limit: Int, $orderBy: String, $order: String
  ) {
    linguistProjects(
    filterNumber: $filterNumber, 
    linguistId: $linguistId, 
    filterStatus: $filterStatus, 
    filterCreationDate: $filterCreationDate, 
    filterDeliveryDateTime: $filterDeliveryDateTime, 
    filterProjectManager: $filterProjectManager, 
    projectType: $projectType,
    offset: $offset, limit: $limit, orderBy: $orderBy, order: $order) {
    linguistProjects {
      id
      linguistId
      projectType
      projectManager
      orderNumber
      status
      deliveryDateTime
      targetLanguage
      sourceLanguage
      total
      creationDate
      projectId
      startDateTime
      endDateTime
      type
      completionDate
    }
    linguistProjectCount {
      allocationCount
      completedCount
      acceptedCount
      totalCount
      pendingCount
      filterCount
    }
  }
}
`
export const ALL_PR_PROJECTS = gql`
query allPRProjects($filterNumber: String, $linguistId: String, $filterStatus: String, $filterCreationDate: String, $filterDeliveryDateTime: String, $filterProjectManager: String, $offset: Int, $limit: Int, $orderBy: String, $order: String) {
  allPRProjects(filterNumber: $filterNumber, linguistId: $linguistId, filterStatus: $filterStatus, filterCreationDate: $filterCreationDate, filterDeliveryDateTime: $filterDeliveryDateTime, filterProjectManager: $filterProjectManager, offset: $offset, limit: $limit, orderBy: $orderBy, order: $order) {
    prProjects {
      id
      linguistId
      projectType
      projectManager
      orderNumber
      status
      deliveryDateTime
      targetLanguage
      sourceLanguage
      total
      creationDate
      projectId
    }
    prProjectCount {
      allocationCount
      completedCount
      acceptedCount
      totalCount
      pendingCount
      filterCount
    }
  }
}
`
export const LINGUIST_LANGUAGE_DETAILS = gql`
query GetUserDataById($getUserDataById: ID!) {
  getUserDataById(id: $getUserDataById) {
    id
    mainLanguage
    otherLanguage
        Rate {
      activity
      languageSource
      languageTarget
      rateType
    }
    mTPeERates {
      activity
      languageSource
      languageTarget
      rateType
    }
    editingRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    onSiteDocumentReviewRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    travelExpensesperday
    cancellationTermsdays
      InterpreterService {
      Backediting {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Notarized {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Patents {
        assignmentTime
        isChecked
      }
      Reconciliations {
        hourRate
        fullDayRate
        experience
        dayRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Revisions {
        dayRate
        experience
        hourRate
        fullDayRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Sworn {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      }
  }
}`
export const LINGUIST_DETAILS = gql`
query GetUserDataById($getUserDataById: ID!) {
  getUserDataById(id: $getUserDataById) {
    id
    firstName
    lastName
    gender
    about
    dob
    nickname
    email
    phone
    skype
    contactMethod
    image
    mainLanguage
    otherLanguage
    liveOtherCountry
    yearOtherCountry
    address1
    address2
    city
    country
    livesin
    postal
    timezone
    college
    startDate
    endDate
    degree
    areaOfStudy
    aboutQualification
    website
    workingFor
    businessType
    sizeClassification
    numberEmployee
    resume
    mainService
    profileTitle
    iban
    bic
    bankname
    taxid
    profileCompletion
    imageForm
    personalForm
    contactForm
    educationForm
    workForm
    businessForm
    paymentForm
    freelanceExperience
    freelancerType
    isBlacklisted
    translation{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    notarisedTranslation{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    swornTranslation{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    backTranslation{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    proofreadingEditing{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    qualityCheck{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    revision{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    reconciliation{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    backeEditing{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    onSiteDocumentReview{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    postDTPProofreading{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    ligthMTPostEditing{
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }
    fullMTPostEditing
    {
      isActive
      values {
        experience
        sourceLanguage
        targetLanguage
        title
      }
    }

    selectedFields
    communication {
      corporateCommunication {
        isActive
        experience
        estimate
      }
      journalismPublications {
        isActive
        experience
        estimate
      }
      pressRelease {
        isActive
        experience
        estimate
      }
      publishing {
        isActive
        experience
        estimate
      }
    }
    marketing {
      adCopy {
        isActive
        experience
        estimate
      }
      generalMarketing {
        isActive
        experience
        estimate
      }
      hotel {
        isActive
        experience
        estimate
      }
      leisure {
        isActive
        experience
        estimate
      }
      printAdvertising {
        isActive
        experience
        estimate
      }
      sportsAndFitness {
        isActive
        experience
        estimate
      }
      tourismTravel {
        isActive
        experience
        estimate
      }
      cruises {
        isActive
        experience
        estimate
      }
      transports {
        isActive
        experience
        estimate
      }
    }
    education {
      parent {
        isActive
        experience
        estimate
      }
      teacher {
        isActive
        experience
        estimate
      }
    }
    foodAndBeverage {
      generalFoodandBeverage {
        isActive
        experience
        estimate
      }
      menu {
        isActive
        experience
        estimate
      }
      workPercentage {
        experience
      }
    }
    retail {
      apparel {
        isActive
        experience
        estimate
      }
      beauty {
        isActive
        experience
        estimate
      }
      consumerProducts {
        isActive
        experience
        estimate
      }
      durables {
        isActive
        experience
        estimate
      }
      toysandCrafts {
        isActive
        experience
        estimate
      }
    }
    insurance {
      forms {
        isActive
        experience
        estimate
      }
      healthInsuranceForm {
        isActive
        experience
        estimate
      }
      policy {
        isActive
        experience
        estimate
      }
    }
    financial {
      accounting {
        isActive
        experience
        estimate
      }
      generalFinance {
        isActive
        experience
        estimate
      }
      lettertoEmployees {
        isActive
        experience
        estimate
      }
      annualReports {
        isActive
        experience
        estimate
      }
      assetManagement {
        isActive
        experience
        estimate
      }
      basicBanking {
        isActive
        experience
        estimate
      }
      benefitsInformation {
        isActive
        experience
        estimate
      }
      businessCards {
        isActive
        experience
        estimate
      }
      financialInstruments {
        isActive
        experience
        estimate
      }
      generalInvestment {
        isActive
        experience
        estimate
      }
      hedgingRiskManagement {
        isActive
        experience
        estimate
      }
      investmentAnalyses {
        isActive
        experience
        estimate
      }
      marketCommentary {
        isActive
        experience
        estimate
      }
      prospectus {
        isActive
        experience
        estimate
      }
      securitizations {
        isActive
        experience
        estimate
      }
    }
    humanResources {
      handbooksAndManuals {
        isActive
        experience
        estimate
      }
      newsletters {
        isActive
        experience
        estimate
      }
      policy {
        isActive
        experience
        estimate
      }
    }
    learningandDevelopment {
      businessandSoftSkills {
        isActive
        experience
        estimate
      }
      educationK12 {
        isActive
        experience
        estimate
      }
      ethicsandCompliance {
        isActive
        experience
        estimate
      }
      financialSAPOracle {
        isActive
        experience
        estimate
      }
      healthandSafety {
        isActive
        experience
        estimate
      }
      managementDevelopment {
        isActive
        experience
        estimate
      }
      salesTraining {
        isActive
        experience
        estimate
      }
      technical {
        isActive
        experience
        estimate
      }
    }
    legal {
      contracts {
        isActive
        experience
        estimate
      }
      depositionNotice {
        isActive
        experience
        estimate
      }
      employeeCorrespondence {
        isActive
        experience
        estimate
      }
      federalCourt {
        isActive
        experience
        estimate
      }
      financial {
        isActive
        experience
        estimate
      }
      governmentNGO {
        isActive
        experience
        estimate
      }
      insurance {
        isActive
        experience
        estimate
      }
      leaseAgreement {
        isActive
        experience
        estimate
      }
      mergersandAcquisitions {
        isActive
        experience
        estimate
      }
      personalDocuments {
        isActive
        experience
        estimate
      }
      stateCourt {
        isActive
        experience
        estimate
      }
      termsandConditions {
        isActive
        experience
        estimate
      }
      courtPapers {
        isActive
        experience
        estimate
      }
    }
    patents {
      automotivePatents {
        isActive
        experience
        estimate
      }
      chemicalPatents {
        isActive
        experience
        estimate
      }
      engineeringPatents {
        isActive
        experience
        estimate
      }
      generalPatents {
        isActive
        experience
        estimate
      }
      pharmaOrMedicalPatents {
        isActive
        experience
        estimate
      }
      semiconductorPatents {
        isActive
        experience
        estimate
      }
      technicalPatents {
        isActive
        experience
        estimate
      }
    }
    technical {
      aeronauticsAerosapace {
        isActive
        experience
        estimate
      }
      automation {
        isActive
        experience
        estimate
      }
      automotive {
        isActive
        experience
        estimate
      }
      construction {
        isActive
        experience
        estimate
      }
      electronics {
        isActive
        experience
        estimate
      }
      engineeringChemical {
        isActive
        experience
        estimate
      }
      engineeringCivil {
        isActive
        experience
        estimate
      }
      engineeringGeneral {
        isActive
        experience
        estimate
      }
      engineeringMechanical {
        isActive
        experience
        estimate
      }
      environmental {
        isActive
        experience
        estimate
      }
      iTDocandHelpUA {
        isActive
        experience
        estimate
      }
      iTERPOrCRM {
        isActive
        experience
        estimate
      }
      iTGames {
        isActive
        experience
        estimate
      }
      iTGeneral {
        isActive
        experience
        estimate
      }
      iTGraphicsSoftware {
        isActive
        experience
        estimate
      }
      iTHardware {
        isActive
        experience
        estimate
      }
      iTSoftwareUI {
        isActive
        experience
        estimate
      }
      iTMarketing {
        isActive
        experience
        estimate
      }
      iTSoftwareUIIVRS {
        isActive
        experience
        estimate
      }
      iTSoftwareUISoftwareMarketing {
        isActive
        experience
        estimate
      }
      iTSoftwareUISoftwareTraining {
        isActive
        experience
        estimate
      }
      iTTechincalMarketing {
        isActive
        experience
        estimate
      }
      machinery {
        isActive
        experience
        estimate
      }
    }
    lifeScience {
      batchRecords
      biochemistryDrugComposition
      justificationLetter
      changeforProtocolSummary
      clinicalStudyReport
      clinicalReportedOutcome
      clinicalReportedProtocol
      cRFCaseReviewForm
      cROClinicianReportedOutcome
      clinicalTrialAgreement
      cVCurriculumVitae
      dischargeSummary
      doctorPatientTranscript
      druglabel
      dLearning
      dthicsCommitteeLetter
      financialDisclosure
      generalLifeSciences
      dlobalCommunications
      handwrittenPatientNotes
      healthQuestionnaireNonClinical
      healthcareMarketingClinical
      healthcareMarketingNonClinical
      investigatorBrochure
      informedConsentForm
      impd
      indemnificationLetter
      investigatorMeeting
      investigatorSiteBinderDocs
      iVRSScripts
      labManualFlowchart
      localECSubmissionDocuments
      mAAeCTDNDA
      manufacturerAuthorization
      medicalLicenses
      neuropsychologicalAssessment
      normalLabRanges
      observerReportedOutcome
      packageInsert
      plainLanguageSummary
      patientMedicalReport
      patientBrochure
      patientCard
      patientDiary
      patientInformationLeaflet
      patientRecruitmentMaterial
      productMonograph
      powerofAttorney
      patientReportedOutcomes
      productCharacteristicsSummary
      publicHealthInformation
      qualifiedPersonCertificate
      rASubmissionLetter
      regulatorySubmissionLetter
      sap
      scientificJournalsArticles
      seriousAdverseEventsForm
      siteContract
      standardOperatingProcedures
      studyBudget
      training
    }
    medicalAreas {
      anesthetics
      animalHealth
      cardiology
      dental
      dermatology
      elderlyMedicine
      emergencyMedicine
      endocrinology
      formsProcedures
      gastroenterology
      generalMedicine
      generalPractice
      gynecology
      healthNutrition
      hematology
      hepatology
      hereditaryDisordersGenetics
      immunology
      infectiousDisease
      medicalMarketing
      neurology
      nurses
      obstetrics
      oncology
      ophthalmology
      otolaryngologyENT
      pediatrics
      pharmacists
      physicalMedicalRehabilitation
      psychiatry
      radiology
      resipirotaryPumonology
      rheumatology
      surgery
      thoracicMedicine
      urology
      vaccines
      allergy
    }
    medicalDevices {
      consumerDevices
      dental
      iFUDFU
      hospitalEquipment
      imagingRadiotherapy
      inVitroDiagnostics
      orthopedics
      shonin
      softwareManuals
      stentsGraftsCatheters
      surgicalInstruments
      vascularInterventions
      labelling
      marketing
    }
    medicalMaterials {
      medicalEducation
      medicalManuals
      medicalProcedures
      medicalReference
      medicalResearch
      medicalReviews
      medicalWeb
    }
    digitalMarketing {
      searchEngineOptimization
      searchEngineMarketing
      payPerClick
    }
    socialMedia {
      communityManagement
    }
    other {
      isActive
      experience
      estimate
    }
    Rate {
      activity
      languageSource
      languageTarget
      rateType
    }
    mTPeERates {
      activity
      languageSource
      languageTarget
      rateType
    }
    editingRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    onSiteDocumentReviewRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    travelExpensesperday
    cancellationTermsdays
      InterpreterService {
      Backediting {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Notarized {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Patents {
        assignmentTime
        isChecked
      }
      Reconciliations {
        hourRate
        fullDayRate
        experience
        dayRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Revisions {
        dayRate
        experience
        hourRate
        fullDayRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      Sworn {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      advisoryBoards {
        assignmentTime
        isChecked
      }
      agribusiness {
      
        assignmentTime
        isChecked
      }
      asylum {
        assignmentTime
        isChecked
      }
      attorney {
        assignmentTime
        isChecked
      }
      audit {
        assignmentTime
        isChecked
      }
      automotive {
        assignmentTime
        isChecked
      }
      aviation {
        assignmentTime
        isChecked
      }
      backTranlation {
        dayRate
        experience
        fullDayRate
        hourRate
        minuteRate
        sourceLanguage
        targetLanguage
      }
      businessMeet {
        assignmentTime
        isChecked
      }
      camera
      cancelationTerm
      computerHardware {
        assignmentTime
        isChecked
      }
      culturalConsultant {
        assignmentTime
        isChecked
      }
      cultureArts {
        assignmentTime
        isChecked
      }
      currency
      freelanceExperience
      freelancerType
      hardware {
        headphones
        microphone
        printer
      }
      deposition {
        assignmentTime
        isChecked
      }
      diplomacy {
        assignmentTime
        isChecked
      }
      disasterRecovery {
        assignmentTime
        isChecked
      }
      disciplinaryMeet {
        assignmentTime
        isChecked
      }
      energy {
        assignmentTime
        isChecked
      }
      engineering {
        assignmentTime
        isChecked
      }
      euParliament {
        assignmentTime
        isChecked
      }
      fcpa {
        assignmentTime
        isChecked
      }
      finance {
        assignmentTime
        isChecked
      }
      foodBeverage {
        assignmentTime
        isChecked
      }
      healthcareOffice {
        assignmentTime
        isChecked
      }
      workerCompensation {
        isChecked
        assignmentTime
      }
      
    unitedNations {
      isChecked
      assignmentTime
    }
      trial {
        isChecked
        assignmentTime
      }
      travelExpense
      tourGuide {
        isChecked
        assignmentTime
      }
      studentEvaluations {
        isChecked
        assignmentTime
      }
      stateDepartment {
        isChecked
        assignmentTime
      }
      sports {
        isChecked
        assignmentTime
      }
      speedConnection
      specialEducation {
        isChecked
        assignmentTime
      }
      socialServices {
        isChecked
        assignmentTime
      }
      shareholderMeet {
        isChecked
        assignmentTime
      }
      retail {
        isChecked
        assignmentTime
      }
      redundancy {
        isChecked
        assignmentTime
      }
      railroads {
        isChecked
        assignmentTime
      }
      qualityChecks {
        sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      publicHealth {
        isChecked
        assignmentTime
      }
      proofReading {
        sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      preferredBrowser
      phoneInterpretation {
        sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      personalAssistant {
        isChecked
        assignmentTime
      }
      parentMeet {
        isChecked
        assignmentTime
      }
      overPhoneAvailable
      newRequestTime
      mentalHealth {
        isChecked
        assignmentTime
      }
      medicalExam {
        isChecked
        assignmentTime
      }
      medicalDevice {
        isChecked
        assignmentTime
      }
      mediaPress {
        isChecked
        assignmentTime
      }
      marketing {
        isChecked
        assignmentTime
      }
      maritime {
        isChecked
        assignmentTime
      }
      machine
      listClient
      landLines
      isSwornShow
      isRevisionsshow
      isPhoneInterpretation
      isNotarizedshow
      isBackshow
      investigatorsMeeting {
        isChecked
        assignmentTime
      }
      hospitalExam {
        isChecked
        assignmentTime
      }
      insurance {
        isChecked
        assignmentTime
      }
      intellectual {
        isChecked
        assignmentTime
      }
      humanResources {
        isChecked
        assignmentTime
      }
      iepMeet {
        isChecked
        assignmentTime
      }
      internationalDevelopment {
        isChecked
        assignmentTime
      }
      
    }
  }
}
`

export const ALL_LINGUISTS = gql`
  query AllLinguists(
    $filterStatus: String
    $filterName: String
    $filterSourceLanguage: String
    $filterTargetLanguage: String
    $filterCity:String
    $filterService: [String]
    $filterRegion:String
    $startDate: String
    $endDate: String
    $offset: Int
    $limit: Int
    $clientId: String
    $projectType: String
    $filterServices:[String]
  ) {
    allLinguists(
      filterStatus: $filterStatus
      filterName: $filterName
      filterSourceLanguage: $filterSourceLanguage
      filterTargetLanguage: $filterTargetLanguage
      City:$filterCity
      Region: $filterRegion
      mainService:$filterService
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
      clientId: $clientId
      projectType: $projectType
      filterServices:$filterServices
    ) {
      linguists {
        id
        firstName
        lastName
        email
        gender
        phone
        areaOfStudy
        contactMethod
        profileCompletion
        type
        dob
        city
        address
        otherLanguage
        mainLanguage
        country
        iban
        resume
        image
        mainService
        feedbackScore
        pmFeedback
        prFeedback
        totalProjectsLinguistCount
        projectsForThisClientCount
        feedbackCI
        feedbackBZ
        sourceLanguages
        targetLanguages
        status
      }
      linguistsCount {
        totalCount
        completedCount
        mostlyCompletedCount
        missingInfromationCount
        blockedLinguist
      }

    }
  }
`;
export const LINGUISTS_LIST_FOR_PRE_BOOKING = gql`
  query PreBookingLinguists(
    $filterStatus: String
    $filterName: String
    $filterSourceLanguage: String
    $filterTargetLanguage: String
    $filterCity:String
    $filterService: [String]
    $filterRegion:String
    $startDate: String
    $endDate: String
    $clientId: String
    $projectType: String
    $filterServices:[String]
  ) {
    preBookingLinguists(
      filterStatus: $filterStatus
      filterName: $filterName
      filterSourceLanguage: $filterSourceLanguage
      filterTargetLanguage: $filterTargetLanguage
      City:$filterCity
      Region: $filterRegion
      mainService:$filterService
      startDate: $startDate
      endDate: $endDate
      clientId: $clientId
      projectType: $projectType
      filterServices:$filterServices
    ) {
      linguists {
        id
        firstName
        lastName
        email
        gender
        phone
        areaOfStudy
        contactMethod
        profileCompletion
        type
        dob
        city
        address
        otherLanguage
        mainLanguage
        country
        iban
        resume
        image
        mainService
        feedbackScore
        pmFeedback
        prFeedback
        totalProjectsLinguistCount
        projectsForThisClientCount
        feedbackCI
        feedbackBZ
        sourceLanguages
        targetLanguages
        status
      }
      linguistsCount {
        totalCount
        completedCount
        mostlyCompletedCount
        missingInfromationCount
        blockedLinguist
      }

    }
  }
`;

export const TRANSLATION_LINGUISTS = gql`
  query TranslationLinguists(
    $filterStatus: String
    $filterName: String
    $filterSourceLanguage: String
    $filterTargetLanguage: String
    $filterCity:String
    $filterService: [String]
    $filterRegion:String
    $deliveryDate:String
    $filterServices:[String]
    $offset: Int
    $limit: Int
    $clientId: String
    $projectType: String
  ) {
    translationLinguists(
      filterStatus: $filterStatus
      filterName: $filterName
      filterSourceLanguage: $filterSourceLanguage
      filterTargetLanguage: $filterTargetLanguage
      City:$filterCity
      Region: $filterRegion
      mainService:$filterService
      filterServices:$filterServices
      deliveryDate:$deliveryDate
      offset: $offset
      limit: $limit
      clientId: $clientId
      projectType: $projectType

    ) {
      linguists {
        id
        firstName
        lastName
        email
        gender
        phone
        areaOfStudy
        contactMethod
        profileCompletion
        type
        dob
        city
        address
        otherLanguage
        mainLanguage
        country
        iban
        resume
        image
        mainService
        feedbackScore
        pmFeedback
        prFeedback
        totalProjectsLinguistCount
        projectsForThisClientCount
        feedbackCI
        feedbackBZ
        sourceLanguages
        targetLanguages
        status
        tradosEmail
      }
      linguistsCount {
        totalCount
        completedCount
        mostlyCompletedCount
        missingInfromationCount
        blockedLinguist
      }

    }
  }
`;
export const BARZANO_PRE_BOOKING_LINGUISTS = gql`
  query TranslationBookingLinguists(
    $filterStatus: String
    $filterName: String
    $filterSourceLanguage: String
    $filterTargetLanguage: String
    $filterService: [String]
    $deliveryDate:String
    $filterServices:[String]
    $clientId: String
    $projectType: String
  ) {
    translationPreBookingLinguists(
      filterStatus: $filterStatus
      filterName: $filterName
      filterSourceLanguage: $filterSourceLanguage
      filterTargetLanguage: $filterTargetLanguage
      mainService:$filterService
      filterServices:$filterServices
      deliveryDate:$deliveryDate
      clientId: $clientId
      projectType: $projectType

    ) 
  }
`;
export const ALL_LINGUISTS_FEEDBACK = gql`
query AllLinguistFeedbacks($linguistsIds: [String], $clientId: String) {
  allLinguistFeedbacks(linguistsIds: $linguistsIds, clientId: $clientId) {
    feedbackScore
    id
    totalProjectsLinguistCount
    projectsForThisClientCount
  }
}`



export const LIST_LINGUISTS = gql`
  query Linguistdetails(
    $filterStatus: String
    $filterName: String
    $filterSourceLanguage: String
    $filterTargetLanguage: String
    $filterCity:String
    $mainService: [String]
    $filterRegion:String
    $filterServices:[String]
    $offset: Int
    $limit: Int
    $clientId: String
    $projectType: String
    $order: String
    $orderBy: String
  ) {
    Linguistdetails(
      filterStatus: $filterStatus
      filterName: $filterName
      filterSourceLanguage: $filterSourceLanguage
      filterTargetLanguage: $filterTargetLanguage
      City:$filterCity
      Region: $filterRegion
      mainService:$mainService
      filterServices:$filterServices
      offset: $offset
      limit: $limit
      clientId: $clientId
      projectType: $projectType
      order: $order
      orderBy: $orderBy
    ) {
      linguists {
        id
        firstName
        lastName
        email
        gender
        phone
        areaOfStudy
        contactMethod
        profileCompletion
        type
        dob
        city
        address
        otherLanguage
        mainLanguage
        country
        iban
        resume
        image
        mainService
        feedbackScore
        pmFeedback
        prFeedback
        totalProjectsLinguistCount
        projectsForThisClientCount
        feedbackCI
        feedbackBZ
        sourceLanguages
        targetLanguages
      }
      linguistsCount {
        filterCount
        totalCount
        completedCount
        mostlyCompletedCount
        missingInfromationCount
        blockedLinguist
      }

    }
  }
`;
export const BLACKLIST_LINGUISTS = gql`
  query getBlacklistLinguist(
    $filterStatus: String
    $filterName: String
    $offset: Int
    $limit: Int
    $projectType: String
    $order: String
    $orderBy: String
  ) {
    getBlacklistLinguist(
      filterStatus: $filterStatus
      filterName: $filterName
      offset: $offset
      limit: $limit
      projectType: $projectType
      order: $order
      orderBy: $orderBy
    ) {
      linguists {
        id
        firstName
        lastName
        email
        gender
        phone
        areaOfStudy
        contactMethod
        profileCompletion
        type
        dob
        city
        address
        otherLanguage
        mainLanguage
        country
        iban
        resume
        image
        mainService
        sourceLanguages
        blacklistTranslation
        blacklistInterpretation
        isBlacklisted
      }
      linguistsCount {
        filterCount
        totalCount
        completedCount
        mostlyCompletedCount
        missingInfromationCount
        blockedLinguist
      }

    }
  }
`;
export const GET_FORMATTING_DETAILS = gql`
query FormatterDetails($allocationId:String){
  expenseAllocationId(allocationId:$allocationId){
    id
    totalWords
    projectManager
    title
    status
    orderNo
    projectId
    type
    endDateTime
    order_details
    formatter{
      id
      firstName
      lastName
      email
    }
  }
}

`;
export const GET_SINGLE_ALLOCATION = gql`
query AllocationById($allocationId: String) {
  allocationById(allocationId: $allocationId) {
    id
    linguistId
    clientId
    projectType
    projectManager
    orderNumber
    status
    deliveryDateTime
    targetLanguage
    sourceLanguage
    total
    creationDate
    projectId
    reviewReasons
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    startDateTime
    endDateTime
    notes
    newWords
    ratePerWord
    pmFeedback
    feedbackCI
    feedbackBZ
    firstName
    lastName
    urgency
    completionDate
  }
}
`
export const GET_SINGLE_PR_ALLOCATION = gql`
query PRAllocationById($allocationId: ID!) {
  prallocationById(id: $allocationId) {
    id
    linguistId
    projectType
    projectManager
    orderNumber
    status
    deliveryDateTime
    targetLanguage
    sourceLanguage
    total
    creationDate
    projectId
    reviewReasons
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    startDateTime
    endDateTime
    notes
    newWords
    ratePerWord
    linguistallocations
  }
}
`
export const PR_ALLOCATION_BY_ID = gql`
query PRAllocationById($allocationId: ID!, $email: String!) {
  prallocationById(id: $allocationId) {
    id
    linguistId
    projectType
    projectManager
    orderNumber
    status
    deliveryDateTime
    targetLanguage
    sourceLanguage
    total
    creationDate
    projectId
    reviewReasons
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    startDateTime
    endDateTime
    notes
    newWords
    ratePerWord
    linguistallocations
  }
  getUserData(email: $email) {
    firstName
    lastName
    image
    resume
    email
    dob
    phone
    mainLanguage
    otherLanguage
    country
    gender
  }
}
`
export const GET_PR_ALLOCATION = gql`
query PRAllocationById($allocationId: ID!) {
  prallocationById(id: $allocationId) {
    id
    linguistId
    projectType
    orderNumber
    status 
    projectId
    linguistallocations
  }
}`

// ==============> COUNT <================

export const ALL_COUNTS = gql`
query AllCounts($filterStatus: [String],  $filterLinguistStatus:String
  $filterCreationDate: [String]
  $filterStartDate: [String] 
  $offset: Int
  $limit: Int
  $order: String
  $orderBy: String
  ) {
allInterpretationOrders(filterStatus: $filterStatus,
   filterCreationDate:$filterCreationDate, 
   filterStartDate: $filterStartDate, 
  limit: $limit,
  offset: $offset,
  order: $order,
  orderBy: $orderBy,
   ) {
  interpretationOrderCount {
    totalCount
    deliveredCount
    allocatedCount
    claimedCount
  }
}
allTranslationOrders(filterStatus: $filterStatus, filterCreationDate:$filterCreationDate, 
  filterDeliveryDateTime: $filterStartDate,
   limit: $limit,
   offset: $offset,
   order: $order,
   orderBy: $orderBy,
   ) {
  translationOrderCount {
    totalCount
    deliveredCount
    allocatedCount
    claimedCount
  }
}
allBarzanoOrders(
  filterStatus: $filterStatus, 
  filterCreationDate:$filterCreationDate, 
  filterDeliveryDateTime: $filterStartDate,
   limit: $limit,
   offset: $offset,
   order: $order,
   orderBy: $orderBy,
   
   ) {
  barzanoOrderCount {
    totalCount
    deliveredCount
    allocatedCount
    claimedCount
  }
}
allVideoConferenceOrders(filterStatus: $filterLinguistStatus ) {
  videoConferenceOrderCount {
    totalCount
    deliveredCount
    allocatedCount
    claimedCount
  }
}
allLinguists(filterStatus: $filterLinguistStatus) {
  linguistsCount {
    totalCount
  }
}
}
`;


export const ALL_QUOTE_COUNTS = gql`
  query AllQuoteCounts {
    allInterpretationQuoteCount {
      interpretationOrderCount {
        TBSCount
        closedCount
        totalCount
        openCount
        awardedCount
        claimedCount
      }
    }
    allTranslationQuoteCount {
      translationOrderCount {
        TBSCount
        closedCount
        totalCount
        openCount
        awardedCount
        claimedCount
      }
    }
 



  }
`;

export const FILTER_QUOTE_AND_PROJECT_COUNT = gql`
query FilterQuoteAndProject($date: String) {
  quotesOrdersCount(date: $date) {
    filterQuoteCount
    filterProjectCount
  }
   
}
`;
export const FILTER_AWARDED_QUOTE_AND_PROJECT_TODAL = gql`
query FilterAwardedQuoteAndProject($date: String) {
  awardedQuoteTotal (date: $date) {
    filterAwarderQuoteCount
      }
  
}
`;
export const AWARDED_QUOTE_AND_PROJECT_TODAL = gql`
query FilterAwardedQuoteAndProject($year: String) {
  translationProjectTotal (year: $year) {
    filterAwarderQuoteCount
      }
    InterpretaionProjectTotal (year: $year) {
    filterAwarderQuoteCount
 
  }
}
`;
export const FILTER_QUOTE_COUNTS = gql`
query AllQuoteCounts($date: String       ) {
  translationQuoteOrdersCount(date: $date) {
  filterQuoteCount
  filterProjectCount
}
InterpretationQuoteOrdersCount(date: $date) {
  filterQuoteCount
  filterProjectCount
}

}
`;
// LINGUIST PORTAL


export const FETCH_EXPERTISE = gql`
query GetTranslationExpertise {
    getTranslationExpertise {
      selectedFields
      communication {
        corporateCommunication {
          isActive
          experience
          estimate
        }
        journalismPublications {
          isActive
          experience
          estimate
        }
        pressRelease {
          isActive
          experience
          estimate
        }
        publishing {
          isActive
          experience
          estimate
        }
      }
      marketing {
        adCopy {
          isActive
          experience
          estimate
        }
        generalMarketing {
          isActive
          experience
          estimate
        }
        hotel {
          isActive
          experience
          estimate
        }
        leisure {
          isActive
          experience
          estimate
        }
        printAdvertising {
          isActive
          experience
          estimate
        }
        sportsAndFitness {
          isActive
          experience
          estimate
        }
        tourismTravel {
          isActive
          experience
          estimate
        }
        cruises {
          isActive
          experience
          estimate
        }
        transports {
          isActive
          experience
          estimate
        }
      }
      education {
        parent {
          isActive
          experience
          estimate
        }
        teacher {
          isActive
          experience
          estimate
        }
      }
      foodAndBeverage {
        generalFoodandBeverage {
          isActive
          experience
          estimate
        }
        menu {
          isActive
          experience
          estimate
        }
        workPercentage {
          experience
        }
      }
      retail {
        apparel {
          isActive
          experience
          estimate
        }
        beauty {
          isActive
          experience
          estimate
        }
        consumerProducts {
          isActive
          experience
          estimate
        }
        durables {
          isActive
          experience
          estimate
        }
        toysandCrafts {
          isActive
          experience
          estimate
        }
      }
      insurance {
        forms {
          isActive
          experience
          estimate
        }
        healthInsuranceForm {
          isActive
          experience
          estimate
        }
        policy {
          isActive
          experience
          estimate
        }
      }
      financial {
        accounting {
          isActive
          experience
          estimate
        }
        generalFinance {
          isActive
          experience
          estimate
        }
        lettertoEmployees {
          isActive
          experience
          estimate
        }
        annualReports {
          isActive
          experience
          estimate
        }
        assetManagement {
          isActive
          experience
          estimate
        }
        basicBanking {
          isActive
          experience
          estimate
        }
        benefitsInformation {
          isActive
          experience
          estimate
        }
        businessCards {
          isActive
          experience
          estimate
        }
        financialInstruments {
          isActive
          experience
          estimate
        }
        generalInvestment {
          isActive
          experience
          estimate
        }
        hedgingRiskManagement {
          isActive
          experience
          estimate
        }
        investmentAnalyses {
          isActive
          experience
          estimate
        }
        marketCommentary {
          isActive
          experience
          estimate
        }
        prospectus {
          isActive
          experience
          estimate
        }
        securitizations {
          isActive
          experience
          estimate
        }
      }
      humanResources {
        handbooksAndManuals {
          isActive
          experience
          estimate
        }
        newsletters {
          isActive
          experience
          estimate
        }
        policy {
          isActive
          experience
          estimate
        }
      }
      learningandDevelopment {
        businessandSoftSkills {
          isActive
          experience
          estimate
        }
        educationK12 {
          isActive
          experience
          estimate
        }
        ethicsandCompliance {
          isActive
          experience
          estimate
        }
        financialSAPOracle {
          isActive
          experience
          estimate
        }
        healthandSafety {
          isActive
          experience
          estimate
        }
        managementDevelopment {
          isActive
          experience
          estimate
        }
        salesTraining {
          isActive
          experience
          estimate
        }
        technical {
          isActive
          experience
          estimate
        }
      }
      legal {
        contracts {
          isActive
          experience
          estimate
        }
        depositionNotice {
          isActive
          experience
          estimate
        }
        employeeCorrespondence {
          isActive
          experience
          estimate
        }
        federalCourt {
          isActive
          experience
          estimate
        }
        financial {
          isActive
          experience
          estimate
        }
        governmentNGO {
          isActive
          experience
          estimate
        }
        insurance {
          isActive
          experience
          estimate
        }
        leaseAgreement {
          isActive
          experience
          estimate
        }
        mergersandAcquisitions {
          isActive
          experience
          estimate
        }
        personalDocuments {
          isActive
          experience
          estimate
        }
        stateCourt {
          isActive
          experience
          estimate
        }
        termsandConditions {
          isActive
          experience
          estimate
        }
        courtPapers {
          isActive
          experience
          estimate
        }
      }
      patents {
        automotivePatents {
          isActive
          experience
          estimate
        }
        chemicalPatents {
          isActive
          experience
          estimate
        }
        engineeringPatents {
          isActive
          experience
          estimate
        }
        generalPatents {
          isActive
          experience
          estimate
        }
        pharmaOrMedicalPatents {
          isActive
          experience
          estimate
        }
        semiconductorPatents {
          isActive
          experience
          estimate
        }
        technicalPatents {
          isActive
          experience
          estimate
        }
      }
      technical {
        aeronauticsAerosapace {
          isActive
          experience
          estimate
        }
        automation {
          isActive
          experience
          estimate
        }
        automotive {
          isActive
          experience
          estimate
        }
        construction {
          isActive
          experience
          estimate
        }
        electronics {
          isActive
          experience
          estimate
        }
        engineeringChemical {
          isActive
          experience
          estimate
        }
        engineeringCivil {
          isActive
          experience
          estimate
        }
        engineeringGeneral {
          isActive
          experience
          estimate
        }
        engineeringMechanical {
          isActive
          experience
          estimate
        }
        environmental {
          isActive
          experience
          estimate
        }
        iTDocandHelpUA {
          isActive
          experience
          estimate
        }
        iTERPOrCRM {
          isActive
          experience
          estimate
        }
        iTGames {
          isActive
          experience
          estimate
        }
        iTGeneral {
          isActive
          experience
          estimate
        }
        iTGraphicsSoftware {
          isActive
          experience
          estimate
        }
        iTHardware {
          isActive
          experience
          estimate
        }
        iTSoftwareUI {
          isActive
          experience
          estimate
        }
        iTMarketing {
          isActive
          experience
          estimate
        }
        iTSoftwareUIIVRS {
          isActive
          experience
          estimate
        }
        iTSoftwareUISoftwareMarketing {
          isActive
          experience
          estimate
        }
        iTSoftwareUISoftwareTraining {
          isActive
          experience
          estimate
        }
        iTTechincalMarketing {
          isActive
          experience
          estimate
        }
        machinery {
          isActive
          experience
          estimate
        }
      }
      lifeScience {
        batchRecords
        biochemistryDrugComposition
        justificationLetter
        changeforProtocolSummary
        clinicalStudyReport
        clinicalReportedOutcome
        clinicalReportedProtocol
        cRFCaseReviewForm
        cROClinicianReportedOutcome
        clinicalTrialAgreement
        cVCurriculumVitae
        dischargeSummary
        doctorPatientTranscript
        druglabel
        dLearning
        dthicsCommitteeLetter
        financialDisclosure
        generalLifeSciences
        dlobalCommunications
        handwrittenPatientNotes
        healthQuestionnaireNonClinical
        healthcareMarketingClinical
        healthcareMarketingNonClinical
        investigatorBrochure
        informedConsentForm
        impd
        indemnificationLetter
        investigatorMeeting
        investigatorSiteBinderDocs
        iVRSScripts
        labManualFlowchart
        localECSubmissionDocuments
        mAAeCTDNDA
        manufacturerAuthorization
        medicalLicenses
        neuropsychologicalAssessment
        normalLabRanges
        observerReportedOutcome
        packageInsert
        plainLanguageSummary
        patientMedicalReport
        patientBrochure
        patientCard
        patientDiary
        patientInformationLeaflet
        patientRecruitmentMaterial
        productMonograph
        powerofAttorney
        patientReportedOutcomes
        productCharacteristicsSummary
        publicHealthInformation
        qualifiedPersonCertificate
        rASubmissionLetter
        regulatorySubmissionLetter
        sap
        scientificJournalsArticles
        seriousAdverseEventsForm
        siteContract
        standardOperatingProcedures
        studyBudget
        training
      }
      medicalAreas {
        anesthetics
        animalHealth
        cardiology
        dental
        dermatology
        elderlyMedicine
        emergencyMedicine
        endocrinology
        formsProcedures
        gastroenterology
        generalMedicine
        generalPractice
        gynecology
        healthNutrition
        hematology
        hepatology
        hereditaryDisordersGenetics
        immunology
        infectiousDisease
        medicalMarketing
        neurology
        nurses
        obstetrics
        oncology
        ophthalmology
        otolaryngologyENT
        pediatrics
        pharmacists
        physicalMedicalRehabilitation
        psychiatry
        radiology
        resipirotaryPumonology
        rheumatology
        surgery
        thoracicMedicine
        urology
        vaccines
        allergy
      }
      medicalDevices {
        consumerDevices
        dental
        iFUDFU
        hospitalEquipment
        imagingRadiotherapy
        inVitroDiagnostics
        orthopedics
        shonin
        softwareManuals
        stentsGraftsCatheters
        surgicalInstruments
        vascularInterventions
        labelling
        marketing
      }
      medicalMaterials {
        medicalEducation
        medicalManuals
        medicalProcedures
        medicalReference
        medicalResearch
        medicalReviews
        medicalWeb
      }
      digitalMarketing {
        searchEngineOptimization
        searchEngineMarketing
        payPerClick
      }
      socialMedia {
        communityManagement
      }
      other {
        isActive
        experience
        estimate
      }
     
    }
  }`;

export const FETCH_ACTIVITY = gql`
query GetTranslationActivty {
  getTranslationActivty {
    translation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    notarisedTranslation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    swornTranslation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    backTranslation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    proofreadingEditing {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    qualityCheck {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    revision {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    reconciliation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    backeEditing {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    onSiteDocumentReview {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    postDTPProofreading {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    ligthMTPostEditing {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
    fullMTPostEditing {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
  }
}`;

export const FETCH_TECHNOLOGY_QUERY = gql`
query GetTranslationTechnologyType {
  getTranslationTechnologyType {
    wordfast {
      wordfastPro3
      wordfastPro5
      wordfastClassic
      wordfastAnywhere
    }
    alchemyCetalyst {
      cetalyst7
      cetalyst8
      cetalyst9
      cetalyst12
    }
    alchemyPublisher {
      publisher2
      publisher3
    }
    trados {
      studio2015
      studio2017
      studio2019
      studio2021
    }
    otherComputerAssistedTranslationTools {
      transcend
      sdlx
      dejaVu
      passolo
      idiom
      msLocStudio
      memoQ
      omegaT
      across
    }
    software {
      adobePdf
      catCourtReporting
      eclipseCourtReporting
      visio
      msOffice
      tmsEtma
    }
    tradosEmail

  }
}
`;

export const FETCH_RATE = gql`
query GetTranslationRate {
  getTranslationRate {
    Rate {
      activity
      languageSource
      languageTarget
      rateType
    }
    mTPeERates {
      activity
      languageSource
      languageTarget
      rateType
    }
    editingRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    onSiteDocumentReviewRates {
      activity
      languageSource
      languageTarget
      rateType
    }
    travelExpensesperday
    cancellationTermsdays
  }
}`;


export const FETCH_USER_QUERY = gql`
  query GetUserData($email: String!) {
    getUserData(email: $email) {
      companyName
      firstName
      email
      lastName 
      nickname
      gender
      image
      dob
      contactMethod
      about
      phone
      skype
      mainLanguage
      otherLanguage
      liveOtherCountry
      yearOtherCountry
      address1
      address2
      city
      country
      livesin
      postal
      timezone
      college
      startDate
      endDate
      degree 
      areaOfStudy
      website
      workingFor
      businessType
      sizeClassification
      numberEmployee
      aboutQualification
      resume
      mainService
      profileTitle
      iban
      bic
      bankname
      taxid
      profileCompletion
    imageForm
    personalForm
    contactForm
    educationForm
    workForm
    businessForm
    paymentForm
          availabilityStatus
    unavailabilityStartDate
    unavailabilityEndDate
    autoSetAvailable
    availabilityReason
    }
  }
`;



export const FETCH_INTERPRETER_SERVICES = gql`
query GetServiceInterpreter {
    getServiceInterpreter {
      asylum {
        isChecked
        assignmentTime
      }
      attorney {
        isChecked
        assignmentTime
      }
      deposition {
        isChecked
        assignmentTime
      }
      fcpa {
        isChecked
        assignmentTime
      }
      insurance {
        isChecked
        assignmentTime
      }
      intellectual {
        isChecked
        assignmentTime
      }
      redundancy {
        isChecked
        assignmentTime
      }
      trial {
        isChecked
        assignmentTime
      }
      workerCompensation {
        isChecked
        assignmentTime
      }
      healthcareOffice {
        isChecked
        assignmentTime
      }
      hospitalExam {
        isChecked
        assignmentTime
      }
      medicalDevice {
        isChecked
        assignmentTime
      }
      medicalExam {
        isChecked
        assignmentTime
      }
      mentalHealth {
        isChecked
        assignmentTime
      }
      audit {
        isChecked
        assignmentTime
      }
      investigatorsMeeting {
        isChecked
        assignmentTime
      }
      computerHardware {
        isChecked
        assignmentTime
      }
      energy {
        isChecked
        assignmentTime
      }
      Patents {
        isChecked
        assignmentTime
      }
      engineering {
        isChecked
        assignmentTime
      }
      advisoryBoards {
        isChecked
        assignmentTime
      }
      agribusiness {
        isChecked
        assignmentTime
      }
      businessMeet {
        isChecked
        assignmentTime
      }
      finance {
        isChecked
        assignmentTime
      }
      humanResources {
        isChecked
        assignmentTime
      }
      shareholderMeet {
        isChecked
        assignmentTime
      }
      aviation {
        isChecked
        assignmentTime
      }
      automotive {
        isChecked
        assignmentTime
      }
      railroads {
        isChecked
        assignmentTime
      }
      maritime {
        isChecked
        assignmentTime
      }
      disciplinaryMeet {
        isChecked
        assignmentTime
      }
      iepMeet {
        isChecked
        assignmentTime
      }
      parentMeet {
        isChecked
        assignmentTime
      }
      specialEducation {
        isChecked
        assignmentTime
      }
      studentEvaluations {
        isChecked
        assignmentTime
      }
      diplomacy {
        isChecked
        assignmentTime
      }
      disasterRecovery {
        isChecked
        assignmentTime
      }
      euParliament {
        isChecked
        assignmentTime
      }
      internationalDevelopment {
        isChecked
        assignmentTime
      }
      publicHealth {
        isChecked
        assignmentTime
      }
      socialServices {
        isChecked
        assignmentTime
      }
      stateDepartment {
        isChecked
        assignmentTime
      }
      unitedNations {
        isChecked
        assignmentTime
      }
      culturalConsultant {
        isChecked
        assignmentTime
      }
      cultureArts {
        isChecked
        assignmentTime
      }
      mediaPress {
        isChecked
        assignmentTime
      }
      personalAssistant {
        isChecked
        assignmentTime
      }
      retail {
        isChecked
        assignmentTime
      }
      sports {
        isChecked
        assignmentTime
      }
      tourGuide {
        isChecked
        assignmentTime
      }
      foodBeverage {
        isChecked
        assignmentTime
      }
      marketing {
        isChecked
        assignmentTime
      }
      landLines
      camera
      speedConnection
      hardware {
        microphone
        headphones
        printer
      }
      machine
      preferredBrowser
      overPhoneAvailable
      freelancerType
      newRequestTime
      freelanceExperience
      listClient
      isRSIshow
      RSI {
        sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      isRCIshow
      RCI {
        sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      isOnsite_Consecutiveshow
      Onsite_Consecutive {
            sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      isSimultaneousshow
      Simultaneous {
            sourceLanguage
        targetLanguage
        experience
        minuteRate
        hourRate
        dayRate
        fullDayRate
      }
      currency
      travelExpense
      cancelationTerm
      isSwornShow
      isPhoneInterpretation
      isNotarizedshow
      isBackshow
      isRevisionsshow
    }
  }`;

export const FETCH_AVAILABILITY_QUERY = gql`
query GetTranslationTechnologyType {
  getTranslationAvailability {
    fulltimeorpartTime
    dailyTranslationOutput
    mostTranslationRequests
    workingProfessionalTranslator {
      years
      months
    }
    experienceOrProfessionalHistory
  }
}`;


export const GET_ONLY_USER_CALLS_STATS = gql`
  query GetUserCalls {
    getOnlyUserCalls {
      callerNum
      callStatus
      DateandTime
      duration
    }
  }
`;

// ==============> GET ALL USER CALLS RECORDS <================

export const GET_ALL_USER_CALLS_STATS = gql`
  query GetAllUsersCalls($date: [String]) {
    getAllUserCalls(date: $date) {
      _id
      name
      avatarUrl
      department
      calls {
          _id
        DateandTime
        callStatus
        callerNum
        duration
        endTime
        startTime
      }
    }
  }
`;

// ==============> GET TWILIO TOKEN <================

export const GET_TOKEN = gql`
  query GetTwilioToken {
    getToken {
      token
    }
  }
`;

// ==============> GET ALL ITALIAN CITIES <================

export const GET_CITIES = gql`
query AllItalionCities {
  allItalionCities {
    cityName
  }
}
`;

// ==============> GET ALL ITALIAN REGIONS <================

export const GET_REGIONS = gql`
query AllItalionRegions {
  allItalionRegions {
    regionName
  }
}
`;

// ==============> GET SINLGE PROJECT FILES <================

export const GET_CLIENT_PROJECT_FILES = gql`
query ProjectFiles($id: String!) {
  projectFiles(id: $id) {
    id
    projectId
    files{
      fileName
      size
    }
    folders {
      client_delivery{
        fileName
        size
        userId
        userType
        createdAt
      }
    
    }
  }
}
`;
export const GET_SINGLE_PROJECT_FILES = gql`
query ProjectFiles($id: String!) {
  projectFiles(id: $id) {
    id
    projectId
    orderNumber
    projectType
    files{
      fileName
      size
    }
    folders {
      original {
        fileName
        size
        userId
        userType
        createdAt
      }
      technician_Material {
        fileName
        size
        userId
        userType
        createdAt
      }
      translated {
        fileName
        size
        linguistId
        userType
        createdAt
        userId
      }
      Support_Material{
        fileName
        size
        userId
        userType
        createdAt
      }
      project_manager{
        fileName
        size
        userId
        userType
        createdAt
        linguistId
      }
      client_delivery{
        fileName
        size
        userId
        userType
        createdAt
      }
      Proofreaded{
        fileName
        size
        linguistId
        createdAt
        userType
      }
    
    }
  }
}
`;

export const GET_SINGLE_PROJECT_FILES_BY_PROJECTID = gql`
query projectFilesbyId($projectId: String!) {
  projectFilesbyId(projectId: $projectId) {
    id
    projectId
    orderNumber
    projectType
    files{
      fileName
      size
    }
    folders {
      original {
        fileName
        size
        createdAt
      }
      translated {
        fileName
        size
        linguistId
        createdAt
      }
      project_manager{
        fileName
        size
        createdAt
        linguistId
      }
      technician_Material {
        fileName
        size
        createdAt
      }
      Support_Material{
        fileName
        size
        createdAt
      }
      project_manager{
        fileName
        size
        createdAt
        linguistId
      }    
      client_delivery{
        fileName
        size
        createdAt
      }
      Proofreaded{
        fileName
        size
        linguistId
        createdAt
      }

    }
  }
}
`;

// ==============> GET ALL PROJECT FILES <================

export const GET_ALL_PROJECT_FILES = gql`
  query allProjectsFiles($offset: Int, $limit: Int, $order: String, $orderBy: String, $orderNumber: String) {
    allProjectsFiles(offset: $offset, limit: $limit, order: $order, orderBy: $orderBy, orderNumber: $orderNumber) {
      count
      projects {
        id
        orderNumber
        projectId
        projectType
        createdAt
        files{
          fileName
          size 
        }
        folders {
          original {
            fileName
            size
          }
          translated {
            fileName
            size
          }    
          project_manager {
            fileName
            size
          }
          Support_Material {
            fileName
            size
          }
          client_delivery{
            fileName
            size
          }
        }
      }
    }
  }
`;
export const GET_POSTS = gql`
query getPosts {
  getPosts {
  _id
    title
    createdAt
    cover
    description
  }
}
`;
export const GET_LOGS = gql`
query Logs($uid: ID) {
  logsByUID(uid: $uid) {
    action
    DateAndTime
    _id
    uid
    description
    user {
      id
      name
    }
  } 
}`
export const GET_QUOTES_AND_PROJECTS = gql`
query allProjectsAndQuotes( $stage: String, $offset: Int, $limit: Int, $clientId: String) {
  allQuoteAndProjects(stage: $stage, offset: $offset, limit: $limit, clientID: $clientId,) {
    allOrderCount {
      totalCount
        }
    allOrders {
      id
      orderNumber
    
      projectManager {
       name 
      }
      accountManager {
        name
      }
      subtotal
      bookedBy {
        firstName
        lastName
      }
      status
      createdAtDate
      stage
      grandTotal
      netTotal
      type
      quoteNumber
      industry {
        name
      }
      translationItem
      interpretationItem
    }
  }
}
`

export const GET_TRANSLATION_BARZANO_ORDERS = gql`
query TranslationQuoteOrdersCount($date: String) {
  translationBarzanoQuoteOrders(date: $date) {
    translationBarzanoProjects {
      id
      bookedBy {
        firstName
        lastName
      }
      translationItem 
      barzanoItem
      companyNameAndId {
        name
      }
      orderNumber
      stage
      status
      netTotal
    }
    translationQuotes {
      id
      orderNumber
      stage
        translationItem 
      companyNameAndId {
        name
      }
      bookedBy {
      firstName
      lastName  
      }
      status
      netTotal
      quoteNumber
    }
    }
}`
export const GET_INTERPRETATION_ORDER_QUOTES = gql`
query InterpretationQuoteOrders($date: String) {
  InterpretationQuoteOrders(date: $date) {
    interpretationProjects
     {
      id
      bookedBy {
        firstName
        lastName
      }
      companyNameAndId {
        name
      }
      interpretationItem
      orderNumber
      stage
      status
      netTotal
    }
     interpretationQuotes  {
      id
     bookedBy {
        firstName
        lastName
      }
      companyNameAndId {
        name
      }
      interpretationItem
      orderNumber
      stage
      status
      netTotal
    }
    }
  }
`
export const GET_TRANSLATION_INTERPRETATION_BARZANO_ORDERS = gql`
query GetProjectsList(
  $orderNumber: String
  $orderType: String
  $offset: Int
  $limit: Int
) {
  getProjects(
    orderNumber: $orderNumber
    orderType: $orderType
    offset: $offset
    limit: $limit
  ){
    ProjectCount {
      totalCount
    }
    Projects {
      id
      orderNumber
      accountManager {
        name
        email
      }
      companyNameAndId {
        id
        name
      }
      projectManager {
        email
        name
      }
      bookedBy
      status
      translationItem
      interpretationItem
      barzanoItem
    }
  }
}`

export const LINGUIST_ALLOCATIONS = gql`
query Linguists($projectId: ID, $clientId: String) {
  linguistAllocation(projectId: $projectId, clientId: $clientId) {
    id
    lastName
    firstName
    status
    sourceLanguage
    targetLanguage
    total
     totalProjectsLinguistCount
    projectsForThisClientCount
    linguistFeedback
    pmFeedback
    feedbackCI
    feedbackBZ
    linguistId
  }
}`
export const GET_ISSUES_BY_PROJECTID = gql`
query GetIssuesByProjectIds($projectIds: [String]!) {
  getIssuesByProjectIds(projectIds: $projectIds) {
  
      id
      issueType
      projectID
      orderNumber
    
  }
}
`;
export const GET_ALL_ISSUES = gql`
query GetIssues($offset: Int, $limit: Int, $orderBy: String, 
  $order: String ,$filterIssueNo: String, 
  $filterProjectNo: String, $filterCompanyName: String,
  $filterIssueStatus: String, 
  $filterIssueCategory: String 
  $filterIssueDepartment: String,
  $filterEmployee: String,
   ) {
  getIssues(offset: $offset, 
    limit: $limit, orderBy: 
    $orderBy, order: $order,
    filterIssueNo: $filterIssueNo, 
    filterProjectNo: $filterProjectNo, 
    filterCompanyName: $filterCompanyName ,
    filterIssueStatus: $filterIssueStatus,
    filterIssueCategory: $filterIssueCategory
    filterIssueDepartment: $filterIssueDepartment,
    filterEmployee: $filterEmployee,
     ) {
    Issues {
      id
    accountManager {
      email
      name
    }
    
    issueType
    projectID
    projectManager {
      email
      name
    }
    projectType
    status
    createdAt
    issueNumber
    orderNumber
     responsibleEmployee
     responsibleDepartment
      companyNameAndId {
        name
        
        id
      }
      reportedBy
    }
    IssueCount {
      totalCount
    }
  }
}`
export const GET_GET_ISSUE_BY_ID = gql`
query GetIssueByID($getIssueByIdId: ID!){
  getIssueByID(id: $getIssueByIdId) {
    id
    issueDeadline
    issuePriority
    issueSummery
    issueType
    projectID
    projectManager {
      id
      name
    }
    companyNameAndId {
      id
      name
    }
    createdAt
    clientCommunication
    accountManager {
      id
      name
    }
    orderNumber
    projectType
    status
    issueCategory
    issueNumber
    reportedBy
    responsibleDepartment
    responsibleEmployee
    solution
    resolution
    responsibleLinguist
  }
}`


export const GET_ISSUES_BY_COMPANY = gql`
query getIssuesByCompany($offset: Int, $limit: Int, $orderBy: String, $order: String ,$filterIssueNo: String, $filterProjectNo: String, $filterCompanyID: String,$filterIssueStatus: String, $filterIssueCategory: String  ) {
  getIssuesByCompany(offset: $offset, 
    limit: $limit, orderBy: 
    $orderBy, order: $order,
    filterIssueNo: $filterIssueNo, 
    filterProjectNo: $filterProjectNo, 
    filterCompanyID: $filterCompanyID ,
    filterIssueStatus: $filterIssueStatus,
     filterIssueCategory: $filterIssueCategory) {
    Issues {
      id
    accountManager {
      email
      name
    }
    
    issueType
    projectID
    projectManager {
      email
      name
    }
    projectType
    status
    createdAt
    issueNumber
    orderNumber
      companyNameAndId {
        name
        
        id
      }
      reportedBy
    }
    IssueCount {
      totalCount
    }
  }
}`

export const GET_TECHNICIANS = gql`
  query allTechnicians {
   allTechnicians {
    id
    firstName
    lastName
    email
  }
}`

export const GET_FORMATTERS = gql`
  query allFormatters {
    allFormatters {
    id
    firstName
    lastName
    email
  }
}`
export const TECHNICIAN_EXPENSE_ALLOCATIONS = gql`
query TechnicianExpenseAllocations(
  $technicianId: String
  $offset: Int
  $limit: Int
  $orderBy: String
  $order: String
  $orderNo: String
  $status:[String]
  $projectManager :String
  $startDate:String
  $endDate:String
) {
  technicianExpenseAllocations(
    technicianID: $technicianId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    order: $order
    orderNo: $orderNo
    status:$status
    projectManager:$projectManager
    startDate:$startDate
    endDate:$endDate
  ) {
    ExpenseAllocationsDetails {
      id
      projectId
      status
      technician {
        firstName
        lastName
        id
        email
      }
      technicianNotes
      title
      total
      type
      orderNo
      projectManager
      bookedBy 
      address1
      address2
      city
      country
      startDateTime

      endDateTime
      createdAt
      companyNameAndId 
    }
    Count {
      filterCount
    }
  }
}`;
export const FORMATTER_EXPENSE_ALLOCATIONS = gql`
query FormatterExpenseAllocations(
  $formatterId: String
  $offset: Int
  $limit: Int
  $orderBy: String
  $order: String
  $orderNo: String
  $status:[String]
  $projectManager :String
  $endDate:String
) {
  formatterExpenseAllocations(
    formatterID: $formatterId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    order: $order
    orderNo: $orderNo
    status:$status
    projectManager:$projectManager
    endDate:$endDate
  ) {
    ExpenseAllocationsDetails {
      id
      projectId
      status
      formatter {
        firstName
        lastName
        id
        email
      }
      title
      totalWords
      type
      orderNo
      projectManager
      endDateTime
      order_details
      createdAt
    }
    Count {
      filterCount
    }
  }
}`;
export const AM_CLIENTS = gql`
query getAMClients($id:String , $date: [String]){
  getAMClients(id:$id, date: $date){
    id
    createdAtDate
    accountManager{
      name
      email
    }
    serviceLevel
    totalSales
    monthlySales
  }
}
`
export const AM_NEW_BUSINESS_REPORT = gql`
query AMNewBusinessReport($amId: ID, $year: String){
  getAMNewBusinessReports(AMId: $amId, year: $year) {
    interpretationOrder{
      id
      companyId
    netTotal
    orderNumber
    status
    createdAtDate
    companyName
    accountManagerName
    type
    marketingChannel
    companyNameAndId
    }
    translationOrder{
      id
      companyId
    netTotal
    status
    createdAtDate
    orderNumber
    companyName
    accountManagerName
    type
    marketingChannel
    }
    companies{
      id
      createdAtDate
      firstSaleDate
      stage
    }
    accountManagers{
      name
      avatarUrl
    }
  }
}
`
export const NEW_BUSINESS_REPORTS = gql`
query NewBusinessReports($year: String,  $type:String){
  getNewBusinessReports(year: $year, type: $type)
}
`
export const AM_CONVERSION_REPORTS = gql`
query AMConversionReports($amId: ID, $year: String) {
  getAMConversionReports(AMId: $amId, year: $year) {
    orderNumber
    netTotal
    status
    createdAtDate
    orderType
    type
    marketingChannel
    accountManagerName
    status
    reasonForClosure
    companyNameAndId
  }
}`
export const CONVERSION_REPORTS = gql`
query ConversionReports($year: String, $type:String) {
  getConversionReports(year: $year, type: $type) 
}`
export const GET_PM_REPORTS= gql`
query PMSalesReport($pmId: String, $year:String) {
  getPMSalesReports(PMId: $pmId, year:$year)
}`
export const GET_PM_MONTHLY_PROJECTS= gql`
query GetPMMonthlyProjects($pmId: String, $monthYear: String) {
  getPMMonthyProjects(PMId: $pmId, monthYear: $monthYear)
}`
export const GET_USER_BY_ID = gql`
query UserById($userByIdId: String) {
  userById(id: $userByIdId) {
    avatarUrl
    department
    jobTitle
    name
    role
    type
    email
    role
    name
    phoneNumber
    description
    language{
      code
      name
      nativename
    }
    personalEmail
    address1
    address2
    city

  }
}`
export const GET_ALL_QUALITY_CASES = gql`
query GetQualityCasesReport($creationDate: [String] , $issueCategory :String) {
  getQualityCasesReport(creationDate: $creationDate , issueCategory : $issueCategory) {
    id
    issueCategory
    issueDeparment
    issueType
    projectType
    responsibleDepartment
    responsibleEmployee
    status
    createdAt
  }
}`
export const GET_OVERALL_SALES_REPORT = gql`
query Query {
  getAllSalesReports
}
`
export const GET_TRANSLATION_SALES_REPORT = gql`
query Query {
  getTranslationSalesReports
}
`
export const GET_BARZANO_MONTLY_SALES_REPORT = gql`
query BarzanoMonthlySalesReport {
  barzanoMonthlySalesReport 
}
`
export const GET_BARZANO_MONHTLY_YTD_SALES_REPORT = gql`
query BarzanoMonthlyYTDSalesReport {
  barzanoMonthlyYTDSalesReport 
}`
export const GET_INTERPRETATION_SALES_REPORT = gql`
query Query {
  getInterpretationSalesReports
}
`
export const GET_PROJECT_BY_DELIVERY_DATE=gql`
query AllOrderCount($days: String!, $offset: Int!, $limit: Int! , $order: String! , $orderBy: String!) {
  allTypeProjects(days: $days, offset: $offset, limit: $limit , order: $order , orderBy: $orderBy) {
    allOrdersCount {
      totalCount
    }
    allOrders {
      type
      orderNumber
      companyNameAndId {
        id
        name
      }
      deliveryDate
      projectManager {
        name
      }
      status
    }
  }
}`
export const GET_DELIVERED_PROJECTS=gql`
query getDeliveredProjects($days: String!, $offset: Int!, $limit: Int!) {
  getDeliveredProjects(days: $days, offset: $offset, limit: $limit) {
    allOrdersCount {
      totalCount
    }
    allOrders {
      type
      orderNumber
      companyNameAndId {
        id
        name
      }
      deliveryDate
      projectManager {
        name
      }
      status
    }
  }
}`
export const GET_LATEST_ALLOCATIONS= gql`
query GetLatestLinguistAllocations {
  getLatestLinguistAllocations {
    id
    firstName
    lastName
    status
    sourceLanguage
    targetLanguage
    deliveryDateTime
    startDateTime
    projectType
    creationDate
    orderNumber
  }
}`

export const GET_ALLOCATION_BY_LINGUIST_ID = gql`
query Query($allocationByLinguistId: [String!], $type: String!) {
  allocationByLinguistId(id: $allocationByLinguistId, type: $type)
}
`
export const GET_PR_ALLOCATION_BY_LINGUIST_ID = gql`
query PRAllocationByLinguistId($prallocationByLinguistId: [String!], $type: String!) {
  prallocationByLinguistId(id: $prallocationByLinguistId, type: $type)
}
`
export const GET_PR_ALLOCATION_BY_ID = gql`
query PRAllocations($projectId: ID) {
  PRAllocations(projectId: $projectId) {
    id
    firstName
    lastName
    email
    city
    mainLanguage
    otherLanguage
    country
    orderNumber
    sourceLanguage
    targetLanguage
    notes
    total
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    newWords
    creationDate
    startDateTime
    endDateTime
    deliveryDateTime
    status
    linguistId
    clientId
    projectsCompleted
    projectManager
    projectId
    projectType
    negotiatedRatePerWord
    negotiatedTotal
    negotiatedRatePerHour
    negotiatedDeliveryDateTime
    reviewReasons
    negotiatedRatePerAdditionalHour
    prDeliveryDate
    linguistallocations
  }
}`
export const DELETE_PR_ALLOCATION = gql`
query DeletePRAllocation($deletePrAllocationId: ID!,$allocationEmail: allocationEmail) {
  deletePRAllocation(id: $deletePrAllocationId , allocationEmail: $allocationEmail)
}
`;
export const CLIENT_EMAIL_QUERY = gql`
query getUserEmail($forgotCode: String!)  {
  getUserEmail(forgotCode: $forgotCode) {
    email
  }
}`;

export const FETCH_CLIENT_QUERY = gql`
query getUserData($id: String!) {
  getClientData(id: $id) 
}
`;
export const GET_BOOK_INERPRETER_FORM_ONE = gql`
query GetBookedFirstForm($getBookedFirstFormId: String!) {
  getBookedFirstForm(id: $getBookedFirstFormId) {
    yourLanguage
    targetLanguage
    eventDate1
    eventDate2
    hours
    serviceType
    experience
    genderInterpreter
    stepOneForm
    activeStatus
    numberInterpreter
  }
}
`
export const GET_BOOK_INERPRETER_FORM_TWO = gql`
query GetBookedSecondForm($getBookedSecondFormId: String!) {
  getBookedSecondForm(id: $getBookedSecondFormId) {
    price
    planName
    activeStatus
  }
}
`
export const GET_BOOK_INERPRETER_FORM_THREE = gql`
query GetBookedThirdForm($getBookedThirdFormId: ID!) {
  getBookedThirdForm(id: $getBookedThirdFormId) {
     interpreterNote
     interpreterFile
     price
     bookInterprer_id
     quoteNo
     planName
     price
     priceEconomy
     priceProfessional
     pricePremium
     numberInterpreter
     yourLanguage
     targetLanguage
     eventDate1
     eventDate2
     hours
     serviceType
     experience
     calculatorHours
     calculatorPresenters
     calculatorLanguages
     WebPrice
     genderInterpreter
     stepTwoForm
     stepThreeForm
     activeStatus
     industry{
      name
      code
     }
  }
}
`
export const GET_WEB_CONFERENCE_DATA = gql`
query GetWebConferencingData($getWebConferencingDataId: ID!) {
  getWebConferencingData(id: $getWebConferencingDataId) {
    id
    webconference_id
    email
    createdAt
    numberHour
    numberParticipant
    numberViewers
    numberLanguages
    totalAmount
    serviceDate
    requireInterpreter
    message
    progress
    numberInterpreter
    yourLanguage
    targetLanguage
    targetWebLanguage{
      code
      name
      nativename
    }
    eventDate1
    eventDate2
    hours
    serviceType
    additionalAmount
    experience
    genderInterpreter
    planName
    price
    priceEconomy
    priceProfessional
    pricePremium
    videoConferenceNote
    videoConferenceFile
    activeStatus
    stepOneForm
    stepTwoForm
    isConfirmed
    isPaid
    additionalServices {
      videoEditing
      recordVideoAndAudio
      postProduction
      pollingSystem
      logoIntegration
    }
    marketingPackage {
      completeBranding
      personalizedEmailEvent
      personalizedEmailInvitation
      platformLinked
      socialMedia
    }
  }
}`

export const FETCH_WEBCONFERENCE_QUERY = gql`
  query ($id: ID!) {
    getWebConferencingData(id: $id) {
      id
      email
      createdAt
      webconference_id
      numberHour
      numberParticipant
      numberViewers
      numberLanguages
      totalAmount
      serviceDate
      additionalServices {
        recordVideoAndAudio
        videoEditing
        postProduction
        logoIntegration
        pollingSystem
      }
      marketingPackage {
        completeBranding
        socialMedia
        platformLinked
        personalizedEmailInvitation
        personalizedEmailEvent
      }
      requireInterpreter
      message
      progress
      numberInterpreter
      yourLanguage
      targetLanguage
      eventDate1
      eventDate2
      hours
      serviceType
      experience
      genderInterpreter
      planName
      price
      priceEconomy
      priceProfessional
      pricePremium
      videoConferenceNote
      videoConferenceFile
      activeStatus
      additionalAmount
      stepOneForm
      stepTwoForm
      stepThreeForm
      stepFourForm
      stepFiveForm
      stepSixForm
    }
  }
`;

export const GET_TRANSLATION_DATA = gql`
  query getTransaltionProjectData($id: ID!) {
    getTransaltionProjectData(id: $id) {
      id
      userEmail
      workEmail
      addressOne
      addressTwo
      city
      postcode
      country
      vat
      confirmPassword
      password
      telephone
      timeZone
      firstName
      rate{
        translateFrom
        language
        rate
      }
      lastName
      companyName
      note
      translationTone
      price
      project_id
      guaranteedDate
      guaranteedTime
      timeZone
      translateTo
      translateFrom
      priceEconomy
      priceProfessional
      pricePremium
      planName
      translationNote
      translationFile {
        fileName
        words
        language
      }
      progress
    }
  }
`;


export const FETCH_PROJECTS_QUERY = gql`
query ($email: String!) {
  getBookedInterpreters(email: $email) {
    id
    bookInterprer_id
    quoteNo
    price
    priceEconomy
    priceProfessional
    pricePremium
    email
    createdAt
    progress
    numberInterpreter
    yourLanguage
    targetLanguage
    eventDate1
    eventDate2
    hours
    serviceType
    experience
    genderInterpreter
    planName
    videoConferenceNote
    videoConferenceFile
    activeStatus
    stepOneForm
    tokenStripe
    stepTwoForm
    stepThreeForm
    calculatorHours
    calculatorPresenters
    calculatorLanguages
    WebPrice
    status
  }
}
`;

export const GET_CLIENT_TOTALS = gql`
query getTotalSpendings($email:String){
  getTotalSpendings(email:$email)
}
`
export const GET_ALL_TRANSLATION_DATA = gql`
  query getTransaltionProjects($email: String!) {
    getTransaltionProjects(email: $email) {
     
      createdAt
      activeStatus
      price
      isConfirmed
      isPaid
    }
  }
`;
export const FETCH_CONFERENCE_QUERY = gql`
  query ($email: String!) {
    getWebConferencing(email: $email) {
      createdAt
      totalAmount
      planName
      activeStatus
      isConfirmed
      isPaid
    }
  }
`;

export const GET_ALL_CLIENT_ORDERS = gql`
query GetAllOrders(
    $filterStage:[String],
    $filterBrand:[String], 
    $filterStatus:[String], 
    $filterCreationDate:[String]
    $filterOrderNo:String,
    $limit: Int,
    $order:String, $orderBy:String, $email:String
    $offset: Int
    ) {
      getAllClientOrders(
        filterStage:$filterStage
      filterBrand:$filterBrand
      email:$email
      filterStatus:$filterStatus
      filterCreationDate:$filterCreationDate
      filterOrderNo:$filterOrderNo,
      limit: $limit
      order:$order
      orderBy:$orderBy
      offset: $offset
    ){
    filterCount
    orders
  }
}`
export const GET_CLEINT_ORDER_BY_ID = gql`
query OrderById($getOrderByIdId: String!, $type: String!){
  getOrderByID(id: $getOrderByIdId, type: $type)
}`
export const LINGUIST_PERSONAL_DETAILS = gql`
query GetUserDataById($getUserDataById: ID!) {
  getUserDataById(id: $getUserDataById) {
    id
    firstName
    lastName
    gender
    about
    dob
    nickname
    email
    phone
    address1
    address2
    city
    country
    dob
    image
    profileTitle
    }
    }
`
export const GET_LINGUIST_COMPLETED_ORDER=gql`
query AllocationsByLinguist($linguistId: ID) {
  allocationsByLinguist(linguistId: $linguistId) {
    orderNumber
    projectType
    total
    prFeedback {
      accuracyTranslation
      grammerSpelling
      technicalTerminology
    }
    pmFeedback {
      communicationAndAvailability
      deliveryCompliance
      layoutAndFormating
    }
    feedbackBZ {
      deliveryCompliance
      communicationAndAvailability
      layoutAndFormating
      translationAccuracy
      tmManagement
    }
    feedbackCI {
      quality
      ethicalStandards
      costEffective
      communication
      timeliness
    }
    status
  }
}`
export const GET_RATING_BY_LINGUIST=gql`
query RatingByLinguist($linguistId: ID) {
  ratingByLinguist(linguistId: $linguistId)
}`
export const GET_ISSUE_BY_LINGUIST_ID =gql`
query IssueCount($linguistId: ID!, $offset: Int, $limit: Int, $orderBy: String, $order: String) {
  getIssuesByLinguist(linguistId: $linguistId, offset: $offset, limit: $limit, orderBy: $orderBy, order: $order) {
    IssueCount {
      totalCount
    }
    Issues {
      accountManager {
        name
      }
      issueNumber
      issueCategory
      issueType
      orderNumber
      projectManager {
        name
      }
      projectType
      responsibleDepartment
      responsibleEmployee
      solution
      status
    }
  }
}`
export const GET_TECHNICIAN = gql`
query getTechnicians(
  $offset: Int
  $limit: Int
  $order: String
  $orderBy: String
  $name: String
) {
  getTechnicians(
    offset: $offset
    limit: $limit
    order: $order
    orderBy: $orderBy
    name: $name
  ) {
    technicians {
      _id
      firstName
      lastName
      email
    }
    filterCount
    totalCount
  }
}
`
export const GET_INCOMPLETE_LINGUIST= gql`
query GetIncompleteLinguist(
  $filterName: String
  $city: String
  $filterMainLanguage: String
  $filterOtherLanguage: String
  $offset: Int
  $limit: Int
  $order: String
  $orderBy: String
) {
  getIncompleteLinguist(
    filterName: $filterName
    City: $city
    filterMainLanguage: $filterMainLanguage
    filterOtherLanguage: $filterOtherLanguage
    offset: $offset
    limit: $limit
    order: $order
    orderBy: $orderBy
  ) {
    linguists {
      _id
      id
      firstName
      lastName
      mainLanguage
      otherLanguage
      phone
      gender
      city
      email
      id
      dob
      mainService
      areaOfStudy
      resume
    }
    linguistsCount {
      filterCount
      totalCount
      blockedLinguist
    }
  }
}`

export const GET_LINGUIST_LIST_BY_ID=gql`
query GetUsersDataById($getUsersDataByIdId: [ID!]) {
  getUsersDataById(id: $getUsersDataByIdId) {
    firstName
    lastName
    email
  }
}`

export const GET_VENDOR_DATA = gql`
  query GetUserData($email: String!) {
    getUserData(email: $email) {
      firstName
      lastName
      email
      address1
      address2
      city
      country
      livesin
      postal
      phone
      iban
      bic
      bankname
      taxid
       availabilityStatus
    unavailabilityStartDate
    unavailabilityEndDate
    autoSetAvailable
    availabilityReason
    }
  }
`;
export const GET_ALLOCATION_BY_LINGUIST=gql`
query GetLinguistAllocations($linguistId: String!, $month: String!) {
  getLinguistAllocations(linguistID: $linguistId, month: $month) {
    lastName
    firstName
    deliveryDateTime
    creationDate
    orderNumber
    projectType
    total
    sourceLanguage
    targetLanguage
    type
    issueNo
    issueID
  }
}`

export const GET_INVOICES_BY_VENDORS = gql`
query GetInvoicesByVendorID($vendorId: String ,$order: String , $orderBy: String) {
  getInvoicesByVendorID(vendorID: $vendorId , order: $order , orderBy: $orderBy) {
    Invoices {
      _id
      createdAt
      
      grandTotal
      
      month
      items {
        title
        description
        total
      }
      invoiceTotal
      invoiceStatus
      invoiceDueDate
      invoiceID
      invoiceTo {
        address1
        address2
        city
        postalCode
        country
        name
        phone
      }
      invoiceFrom {
        address1
        address2
        city
        postalCode
        country
        name
        phone
        bankName
        IBAN
        BIC
        TAXID
      }
    }
    InvoiceCount {
      Paid
      Unpaid
      totalCount
      paidAmount
      totalAmount
      unpaidAmount
      Overdue
      overDueAmount
    }
  }
}`
export const GET_INVOICE_BY_ID = gql`
query GetInvoiceByInvoiceID($invoiceId: String ) {
  getInvoiceByInvoiceID(invoiceID: $invoiceId ) {
    _id
    createdAt
    invoiceDueDate
    invoiceNotes
    legalTerms
    vendorLogo
    discount
    orderNo
    grandTotal
    fattureInvoice
    discountValue
    orderType
    barzanoCaseNo
    orderID
    link
    numeration
    subject
    visible_subject
    payment_account
    invoicePaidDate
    invoiceFrom {
      name
      address1
        address2
        city
        postalCode
        country
      phone
      bankName
      IBAN
      BIC
      TAXID
      email
      province
      VAT
    }
    invoiceID
    invoiceStatus
    invoiceTo {
      name
      address1
        address2
        city
        postalCode
        country
        country_code
      phone
      province
      ei_code
      VAT
      TAXID
      email
      payment_terms

    }
    invoiceTotal
    legalTerms
    invoiceTaxes
    invoiceSubTotal
    type
    barzanoCaseType
    items {
      code
      discount
      title
      description
      total
      issueNo
      issueID
      ppw
      tax
      quantity
      netTotal
    }
    month
    
    vendorID
    
  }
}`
export const GET_MONTH_WITHOUT_INVOICE = gql`
query GetUnpaidMonths($vendorId: String!) {
  getUnpaidMonths(vendorID: $vendorId) {
    month
    totalItems
    isCreated
    items
  }
}`
export const GET_TECHNICIAN_MONTH_WITHOUT_INVOICE = gql`
query getUnpaidTechnicianMonths($vendorId: String!) {
  getUnpaidTechnicianMonths(vendorID: $vendorId) {
    month
    totalItems
    isCreated
  }
}`
export const GET_ALL_INVOICES=gql`
query GetAllInvoices(
  $order: String
  $orderBy: String
  $limit: Int
  $offset: Int
  $invoiceMonth: String
  $invoiceStatus: [String]
  $invoiceId: String
  $type : String
  $filterCreationDate: [String]
) {
  getAllInvoices(
    filterCreationDate: $filterCreationDate
    order: $order
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    invoiceMonth: $invoiceMonth
    invoiceStatus: $invoiceStatus
    invoiceID: $invoiceId
    type: $type
  ) {
    InvoiceCount {
      Paid
      Unpaid
      filterCount
      paidAmount
      totalAmount
      totalCount
      unpaidAmount
      Overdue
      overDueAmount
      
    }
    Invoices {
      _id
      createdAt
      invoiceDueDate
      invoiceNotes
      legalTerms
      vendorLogo
      discount
      orderNo
      grandTotal
      fattureInvoice
      discountValue
      orderType
      barzanoCaseNo
      orderID
      numeration
      link
      subject
      visible_subject
      invoiceFrom {
        name
        address1
          address2
          city
          postalCode
          country
        phone
        bankName
        IBAN
        BIC
        TAXID
        email
        province
        VAT
      }
      invoiceID
      invoiceStatus
      invoiceTo {
        name
        address1
          address2
          city
          postalCode
          country
          country_code
        phone
        province
        ei_code
        VAT
        TAXID
        email
      }
      invoiceTotal
      legalTerms
      invoiceTaxes
      invoiceSubTotal
      type
      barzanoCaseType
      month
      orderType
      barzanoCaseNo
      vendorID
      
    }
  }
}`
export const GET_TECHNICIAN_ALLOCATIONS = gql`
query GetTechnicianAllocations($technicianId: String!, $month: String!) {
  getTechnicianAllocations(technicianID: $technicianId, month: $month) {
    orderNo
    type
    title
    total
    status
    service
    createdAt
  }
}`
export const GET_CALLS_BY_DATE_BY_USER = gql`
query UserCallByMonth($userId: String, $month:String) {
  getCallsByMonth(userID: $userId, month: $month)
}`
export const GET__TOKEN = gql`
query GetToken {
  getCreditSafeToken {
    token
  }
}`
export const GET_COMPANY_LIST = gql`
query GetCompanyList($token: String, $name: String) {
  getCompaniesData(token: $token, name: $name)
}`
export const GET_COMPANY_BY_ID=gql`
query GetCompanyById($id: String, $token: String) {
  getCompanyByID(id: $id, token: $token)
}`
export const GET_HISTORICAL_DATA = gql`
query GetHistoricalData {
  allQuotesData
}`
export const GET_BARZANO_SALES_REPORT=gql`
query GetBarzanoSalesReport($year: String) {
  getBarzanoSalesReports(year: $year)
}`
export const GET_BARZANO_WORDS_COUNT = gql`
query GetBarzanoWordsCountReport($year: String) {
  getBarzanoWordsCount(year: $year)
}
`
export const GET_BARZANO_WEEKLY_WORDS_COUNT = gql`
query GetBarzanoWeeklyWordsCountReport($creationDate: [String]) {
  getBarzanoWeeklyWordsCount(creationDate: $creationDate)
}`

export const GET_BARZANO_ORDERS_ISSUES_COUNT = gql`
query BarzanoProjectsAndIssues($year: String) {
  getBarzanoQualityCasesProject(year: $year)
}
`
export const GET_BARZANO_SUMMERY = gql`
query GetBarzanoSummery($month: String, $year: String, $caseType:String) {
  getBarzanoSummery(month: $month, year: $year , caseType: $caseType)
}`
export const GET_COMPANIES_ADDRESS_LIST=gql`
query getCompaniesAddress($name: String, $brand: String) {
  getCompaniesList(name: $name, brand: $brand)
}`
export const GET_CLIENT_INVOICE_STATUS = gql`
query ClientInvoiceStatus($month: String!, $caseType: String, $orderType: String) {
  clientInvoiceStatus(month: $month, caseType: $caseType, orderType: $orderType)
}`
export const GET_COMPANY_INFO = gql`
query getCompanyInfo($id: String!) {
  getCompaniesInfo(ID: $id)
}
`
export const GET_INVOICES_BY_PROJECTS= gql`
query getInvoiceByProjectID($orderId: ID!) {
  getInvoicesByProject(orderID: $orderId)
}`
export const GET_CONTACT_BY_EMAIL = gql`
query CompanyContactByEmail($email: String!) {
  companyContactByEmail(email: $email) {
    id
    firstName
    taxCode
    lastName
    email
    gender
    phone
    role
    jobTitle
    address1
    address2
    city
    state {
      code
      province
      region
    }
    country {
      code
      label
      phone
    }
    postCode
    status
    companyId
    createdAtDate
  }
}`
export const DELETE_TASKS_LIST = gql`
query DeleteTaskList($ids: [ID!]) {
  deleteTaskList(ids: $ids)
 }`
 export const GET_LOST_QUOTE_BY_REASON = gql`
 query getLostQuotebyReason(
  $offset: Int!
  $limit: Int!
  $orderBy: String!
  $order: String!
  $reasonForClosure: [String]
  $year: String!
  $type:String

) {
  getLostQuote(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    order: $order
    reasonForClosure: $reasonForClosure
    year: $year
    type:$type

  )
}`
 export const GET_INSTANT_QUOTE_PRICE = gql`
 query GetInstantQuotePrice(
  $sourceLanguage: languageDetails
  $targetLanguage: [languageDetails]
  $subject: industryDetails
  $totalWords: Int
  $deliveryDate: String
  $deliveryDateOption: String
) {
  getInstantQuotePrice(
    sourceLanguage: $sourceLanguage
    targetLanguage: $targetLanguage
    Subject: $subject
    totalWords: $totalWords
    deliveryDate: $deliveryDate
    deliveryDateOption: $deliveryDateOption
    )
}`
export const UPLOAD_INSTANT_QUOTE_FILE = gql`
query uploadInstantQuoteFile($docs: Upload) {
  uploadInstantQuoteFile(docs: $docs)
}`
export const GET_CLIENT_DELIVERED_LY_CI_PROJECTS=gql`
query GetClientDeliveryProjects($month: String, $clientId: String!) {
  getClientDeliveredProjects(month: $month, clientID: $clientId)
}`
export const GET_TOTAL_VALUE_MONTHLY_BASED = gql`
query TotalOrdersValues($year: String) {
  totalOrdersValues(year: $year)
}`
export const TOP_CITIES_WITH_CLIENT_SALES = gql`
query TopCiteisWithClientSales($year: String) {
  getTopCitiesWithSales (year: $year)
}`
export const TOP_CLIENTS_BY_SALES = gql`
query TopClientBySales($year: String) {
  getTopClientsBySales(year: $year) 
}`
export const BARZANO_YEARLY_SALES_REPORT = gql`
query TopClientBySales($year: String) {
  getBarzanoYearlySales(year: $year) 
}`
export const NEW_BUSINESS_SALES_STATS= gql`
query NewBusinessReports($year: String) {
  newBusinessReports(year: $year) 
}`
export const TOP_PERFORMERS = gql`
query TopPerformers($dataType: String!, $dateRange: [String]) {
  topPerformers(dataType: $dataType, dateRange: $dateRange)
}`

export const GET_SUPPLIER_LIST=gql`
query SupplierList($name: String) {
  getSupplierList(name: $name)
}`
export const GET_EXPENSE_CATEGORIES_LIST=gql`
query ExpenseCategoryList {
  getExpenseCategoryList
}`
export const GET_PAYMENT_ACCOUNTS = gql`
query ExpenseCategoryList {
  getPaymentAccountList
}`
export const GET_EXPENSES_LIST= gql`
query ExpenseList(
  $supplier: String
  $macroCategory: String
  $category: String
  $expenseDate: [String]
  $paymentStatus: [String]
  $expiryDate: [String]
  $type: String
  $limit: Int
  $offset: Int
  $orderBy: String
  $order: String
) {
  getExpenseList(
    supplier: $supplier
    type: $type
    macroCategory: $macroCategory
    category: $category
    expenseDate: $expenseDate
    paymentStatus: $paymentStatus
    expiryDate: $expiryDate
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    order: $order
  )
}`
export const GET_EXPENSE_BY_ID = gql`
query GetExpenseByID($id: String!) {
  getExpenseByID(id: $id) 
}`
export const GET_S3_BUCKET_DATA =gql`
query GetS3ObjectData($s3Url: String!) {
  getS3ObjectData(s3Url: $s3Url) {
    content
    contentType
  }
}`
export const GET_EXPENSES_AND_REVENUE = gql`
query GetExpensesAndRevenue($type: String!, $year: String!) {
  getAllExpenseAndRevenue(type: $type, year: $year)
} `
 export const GET_EXPENSES_BY_CATEGORY = gql`
 query GetAllExpenseByCategories($type: String!, $year: String!) {
  getAllExpenseByCategories(type: $type, year: $year)
}`
 export const GET_EXPENSES_BY_TYPE = gql`
 query GetAllExpenseByType($type: String!, $year: String!) {
  getAllExpenseByType(type: $type, year: $year)
}`
export const GET_RECENT_EXPENSES = gql`
query GetRecentExpenses($type: String!, $year: String!) {
  getRecentExpenses(type: $type, year: $year)
}`
export const GET_PROJECTS_LIST = gql`
query GetProjectList {
  getProjectsList
}`
export const GET_EXPENESES_BY_MACRO_CATEGORIES = gql`
query GetExpenseByMacroCategory($type: String!, $year: String!) {
  getAllExpenseByMacroCategories(type: $type, year: $year)
}`


export const GET_EXPENESES_BY_COST_TYPE = gql`
query GetAllExpenseByCost($type: String!, $year: String!) {
  getAllExpenseByCost(type: $type,year: $year)
}`
export const GET_MONTHLY_EXPENSES_BY_CATEGORY = gql`
query GetMonthlyExpenseByCategory($macroCategory: String!,$costType:String!, $year: String!) {
  getMonthlyExpenseByCategory(macroCategory: $macroCategory, costType:$costType, year: $year)
}`
export const GET_CLIENTS_LIST = gql`
query GetClientList($type: [String]) {
  getClientsList(type: $type)
}`
export const GET_PROJECT_LIST = gql`
query GetFilterProjectList( $year: String!, $clientId: String!) {
  getFilterdProjectList( year: $year, clientID: $clientId)
}`
export const GET_PROJECT_INFO = gql`
query getProjectInfo($type: String!, $id: String!) {
  getFilterdProjectInfo(type: $type, id: $id)
}`
export const GET_TM_REPORT = gql`
query TMReport($id: ID!) {
  TMReport(id: $id) {
    id
    client
    project
    files
    createdAt
    tmNumber
    projectManager
  }
}`
export const GET_TM_REPORT_LIST = gql`
query AllTMReports(
  $orderBy: String
  $order: String
  $offset: Int
  $limit: Int
  $clientName: String
) {
  allTMReports(
    orderBy: $orderBy
    order: $order
    offset: $offset
    limit: $limit
    clientName: $clientName
  ) {
    id
    client
    project
    files
    createdAt
    tmNumber
    projectManager
  }
}`
export const GET_INVENTORY_ITEMS = gql`
query GetAllInventoryItem(
  $search: String
  $status: String
  $condition: String
  $category: String
  $location: [String]
  $limit: Int
  $offset: Int
  $orderBy: String
  $order: String
) {
  getAllInventories(
    search: $search
    status: $status
    condition: $condition
    category: $category
    location: $location
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    order: $order
  ) {
    total
    filterCount
    inventoryItems {
      id
      createdAt
      name
      brand
      model
      quantity
      location
      status
      stockCode
      purchaseDate
      condition
      category
      description
      images
      notes
    }
  }
}`
export const GET_INVENTORY_BY_ID = gql`
query GetInventoryByID($id: ID!) {
  getInventory(id: $id) {
    id
    createdAt
    name
    category
    brand
    model
    quantity
    location
    status
    stockCode
    purchaseDate
    condition
    description
    images
    notes
  }
}`
export const GET_PRE_BOOKING_REQUESTS = gql`
query AllLinguistPreBookings(
  $item_id: String!
  $projectId: String!
  $name: String
) {
  allLinguistPreBookings(
    item_id: $item_id
    projectId: $projectId
    name: $name
  )
}`
export const GET_BOOKING_REQUESTS = gql`
query AllLinguistPreBookings(
  $item_id: String!
  $projectId: String!
  $offset: Int!
  $limit: Int!
  $orderBy: String!
  $order: String!
  $name: String
) {
  allLinguistBookings(
    item_id: $item_id
    projectId: $projectId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    order: $order
    name: $name
  )
}`
export const GET_PRE_BOOKING_BY_ID = gql`
query Query($getPreBookingByIdId: String) {
  getPreBookingByID(id: $getPreBookingByIdId)
}`
export const GET_BOOKING_BY_ID = gql`
query Query($getPreBookingByIdId: String) {
  getBookingByID(id: $getPreBookingByIdId)
}`
export const GET_FIC_PAYMENT_ACCOUNTS= gql`
  query GetPaymentAccounts {
    getPaymentAccounts 
  }`

export const GET_LINGUIST_ALLOCATION_BY_IDS=gql`
query LinguistAllocationsByAllocationID($IDs: [String]) {
  linguistAllocationsByAllocationID(IDs: $IDs)
}`
export const GET_MANAGEMENT_REPORT=gql`
query getManagementReport($year: String) {
  getManagementReport(year: $year)
}`
export const GET_MANAGEMENT_BRAND_REVENUE_REPORT = gql`
query GetManagementBrandRevenueReport($year: String) {
  getManagementBrandRevenueReport(year: $year)
}`
export const GET_EXPENSES_BY_COST_REPORT=gql`
query GetMonthlyExpenseByCost($year: String) {
  getMonthlyExpenseByCost(year: $year)
}`
export const GET_TICKET_BY_ID = gql`
query TicketByID($ticketByIdId: ID) {
  ticketByID(id: $ticketByIdId) {
    id
    ticket_id
    title
    description
    priority
    category
    createdBy
    location
    assignee
    createdAt
    updatedAt
    completed_date
    due_date
    impact
    status
    attachment
    
    comments {
      createdAt
      text
      user
    }
  }
}`
export const GET_TICKET_LIST = gql`
query TicketList(
  $category: String
  $title: String
  $filterStatus: [String]
  $createdDate: [String]
  $dueDate: [String]
  $offset: Int
  $limit: Int
  $order: String
  $orderBy: String
) {
  allTickets(
    category: $category
    filterStatus: $filterStatus
    title: $title
    createdDate: $createdDate
    dueDate: $dueDate
    offset: $offset
    limit: $limit
    order: $order
    orderBy: $orderBy
  ) {
    tickets {
      id
      ticket_id
      title
      description
      priority
      category
      createdBy
      assignee
      createdAt
      updatedAt
      completed_date
      due_date
      impact
      status
      attachment
    }
    ticketsCount {
      filterCount
      totalCount
      completedCount
      inTestingCount
      inProgressCount
      notStartedCount
    }
  }
}
`
export const GET_ALL_TICKETS_LIST = gql`
query AllTicketList(
  $type: String
  $filterStatus: [String]
  $filterCreationDate: [String]
  $filterDueDate: [String]
  $filterName: String
  $offset: Int
  $limit: Int
  $order: String
  $orderBy: String
) {
  allTicketList(
    type: $type
    filterStatus: $filterStatus
    filterCreationDate: $filterCreationDate
    filterDueDate: $filterDueDate
    filterName: $filterName
    offset: $offset
    limit: $limit
    order: $order
    orderBy: $orderBy
  ) {
    tickets {
      id
      ticket_id
      title
      description
      priority
      category
      createdBy
      assignee
      createdAt
      updatedAt
      completed_date
      due_date
      impact
      location
      status
      attachment
    }
    ticketsCount {
      filterCount
      totalCount
      completedCount
      inTestingCount
      inProgressCount
      notStartedCount
    }
  }
}`
export const GET_DEVELOPERS_LIST = gql`
query ListDeveloper{
  listSoftwareDevelopers {
    id
    name
    address1
    avatarUrl
  }
}`
export const GET_QUOTE_LOST_ANALYSIS_REPORT = gql`
query Query($year: String!, $type: String!, $reasonForClosure: String!) {
  getLostQuoteAnalysis(year: $year, type: $type, reasonForClosure:$reasonForClosure)
}`

export const GET_TOP_VENDORS = gql`
query getTopVendors($year: String!, $type: String!) {
  getTopVendors(year: $year, type: $type)
}`

export const GET_TOP_TRANSLATED_LANGUAGES = gql`
query getTopTranslatedLanguages($year: String!, $type: String!) {
  getTopTranslatedLanguages(year: $year, type: $type)
}`
